import React from 'react';

import useForm from '../../../../hooks/useForm';
import ErrorAlert from '../../../design-system/alerts/error';
import CancelButton from '../../../design-system/buttons/cancel';
import Submit from '../../../design-system/buttons/submit';
import FormFooter from '../../../design-system/form/footer';
import SimpleInput from '../../../design-system/form/input';
import FormLine from '../../../design-system/form/line';
import FormSection from '../../../design-system/form/section';
import Select from '../../../design-system/form/select';
import TimeInput from '../../../design-system/form/time';
import ToggleInput from '../../../design-system/form/toggle';

const CreneauForm = ({
  initialValue,
  axiosConfig,
  saisonId,
  labelSubmit = 'Créer',
}) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue,
    axiosConfig,
    redirectUrl: `/admin/saisons/${saisonId}`,
  });

  const errors =
    requestState.status === 'error' ? requestState.error.errors : {};

  return (
    <form onSubmit={submitForm}>
      <div>
        {requestState.status === 'error' && (
          <ErrorAlert
            className="mt-4"
            message="Un ou plusieurs champs ne sont pas correctement remplis."
          />
        )}
        <div className="border-gray-200">
          <div className="mt-6 sm:mt-5">
            <FormSection className="sm:border-t">
              Informations générales
            </FormSection>
            <FormLine
              label="Code de reference"
              help="Code utilisé pour parler de ce créneau. Se compose d'une lettre pour le jour et d'un nombre pour le numero du creneau dans la journée. S1 est le premier créneau du samedi."
              htmlFor="ref"
            >
              <SimpleInput
                id="ref"
                required
                errors={errors}
                value={formData.ref}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    ref: e.target.value,
                  });
                }}
              />
            </FormLine>
            <FormLine label="Description" htmlFor="intitule">
              <SimpleInput
                errors={errors}
                id="intitule"
                required
                value={formData.intitule}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    intitule: e.target.value,
                  });
                }}
              />
            </FormLine>
            <FormLine
              label="Ouvert?"
              help="Controle si les adhérents peuvent s'inscrire à ce créneau"
              htmlFor="affichage"
            >
              <ToggleInput
                id="affichage"
                required
                checked={formData.affichage}
                onChange={(checked) => {
                  setFormData({
                    ...formData,
                    affichage: checked,
                  });
                }}
              />
            </FormLine>
            <FormLine label="Nombre de places" htmlFor="nbplace_total">
              <SimpleInput
                errors={errors}
                id="nbplace_total"
                required
                type="number"
                value={formData.nbplace_total}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    nbplace_total: parseFloat(e.target.value),
                  });
                }}
              />
            </FormLine>

            <FormSection>Horaires du créneau</FormSection>
            <FormLine label="Jour du créneau" htmlFor="jour">
              <Select
                errors={errors}
                id="jour"
                value={formData.jour}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    jour: e.target.value,
                  });
                }}
              >
                <option value="lundi">Lundi</option>
                <option value="mardi">Mardi</option>
                <option value="mercredi">Mercredi</option>
                <option value="jeudi">Jeudi</option>
                <option value="vendredi">Vendredi</option>
                <option value="samedi">Samedi</option>
                <option value="dimanche">Dimanche</option>
              </Select>
            </FormLine>
            <FormLine label="Heure de début" htmlFor="heure_debut">
              <TimeInput
                id="heure_debut"
                errors={errors}
                required
                value={formData.heure_debut}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    heure_debut: e.target.value,
                  });
                }}
              />
            </FormLine>
            <FormLine label="Heure de fin" htmlFor="heure_fin">
              <TimeInput
                errors={errors}
                id="heure_fin"
                required
                value={formData.heure_fin}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    heure_fin: e.target.value,
                  });
                }}
              />
            </FormLine>

            <FormSection>Age des enfants</FormSection>

            <FormLine label="Age minimale" htmlFor="age_mois_min">
              <SimpleInput
                id="age_mois_min"
                required
                type="number"
                value={formData.age_mois_min}
                errors={errors}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    age_mois_min: parseFloat(e.target.value),
                  });
                }}
              />
            </FormLine>
            <FormLine label="Age maximal" htmlFor="age_mois_max">
              <SimpleInput
                errors={errors}
                id="age_mois_max"
                required
                type="number"
                value={formData.age_mois_max}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    age_mois_max: parseFloat(e.target.value),
                  });
                }}
              />
            </FormLine>
          </div>
        </div>
      </div>
      <FormFooter>
        <CancelButton to={`/admin/saisons/${saisonId}`}>
          Retour à la liste
        </CancelButton>
        <Submit type="submit" disabled={isFormPristine}>
          {labelSubmit}
        </Submit>
      </FormFooter>
    </form>
  );
};

export default CreneauForm;
