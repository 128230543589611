import React, { useEffect } from 'react';

import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import { navigate } from 'gatsby';

import * as DesignSystem from 'src/components/design-system';
import * as Form from 'src/components/design-system/form';

import Cancel from 'src/components/design-system/buttons/cancel';
import Action from 'src/components/design-system/buttons/action';

const PreviewHTML = ({ message, subject }) => {
  const { requestState } = useAxiosNow({
    payload: { message, subject },
    method: 'post',
    url: routes.mailingPreview(),
  });

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rsPreview) => (
        <iframe srcDoc={rsPreview.data} className="w-full h-72" />
      )}
    </DesignSystem.Loading>
  );
};

const Preview = ({ filters, emails, message, subject, created_at }) => {
  return (
    <>
      <DesignSystem.Header
        title="Communications aux adhérents"
        back={{ label: 'Retour à la liste', to: '/admin/mail' }}
      ></DesignSystem.Header>
      <DesignSystem.Card title={`Envoyé le ${created_at}`} className="mt-4">
        <div className="mt-4">
          <div className="block text-sm font-medium text-gray-700 leading-5">
            Destinataires ({emails.length})
          </div>
          <ul className="flex flex-wrap">
            {emails.map((e) => (
              <li className="mt-1 ml-1">
                <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-blue-100 text-blue-800">
                  {e}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-2">
          <div className="block text-sm font-medium text-gray-700 leading-5">
            Sujet
          </div>
          <div className="relative px-2 py-1 mt-1 border border-gray-100 rounded-md shadow-sm">
            {subject}
          </div>
        </div>
        <div className="mt-2">
          <div className="block text-sm font-medium text-gray-700 leading-5">
            Prévisualisation du message
          </div>
          <div className="relative mt-1 rounded-md shadow-sm">
            <PreviewHTML message={message} subject={subject} />
          </div>
        </div>
      </DesignSystem.Card>
    </>
  );
};

const PreviewWithData = ({ id }) => {
  const { requestState } = useAxiosNow({
    url: routes.mailingView(id),
  });

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rs) => <Preview {...rs.data.data} />}
    </DesignSystem.Loading>
  );
};

export default PreviewWithData;
