import React from 'react';

import * as DesignSytem from 'src/components/design-system';
import Button from 'src/components/design-system/buttons/action';
import useAxios from 'src/hooks/axios/useAxios';

import routes from 'src/config/routes';

const dataToLines = (data, onClick) =>
  data.map((c) => [
    c.enfant_name,
    <div className="text-right">
      <DesignSytem.Link to={`/admin/familles/${c.famille_id}`}>
        Voir la famille
      </DesignSytem.Link>
      <Button
        mustConfirm
        confirmationMessage={`Confirmez-vous avoir vu le carnet de santé de ${c.enfant_name} et que celui-ci contenait toutes les vaccinations obligatoires?`}
        onClick={onClick(c.enfant_id, 'carnet')}
        className="ml-8"
      >
        Carnet vu
      </Button>
      <Button
        mustConfirm
        confirmationMessage={`Confirmez-vous avoir récupéré un certificat de vaccinations obligatoires pour ${c.enfant_name}?`}
        onClick={onClick(c.enfant_id, 'certificat')}
        className="ml-8"
      >
        Certificat vu
      </Button>
    </div>,
  ]);

const Vaccinations = ({ data, reload }) => {
  const { load: updateVaccination } = useAxios({
    url: routes.fixVaccination(),
    method: 'POST',
  });

  const onClick = (id, type) => (e) => {
    e.preventDefault();
    updateVaccination({
      payload: { id, type },
    }).then(reload);
  };

  return data && data.length > 0 ? (
    <DesignSytem.Table lines={dataToLines(data, onClick)} className="mt-4" />
  ) : (
    <DesignSytem.Card className="mt-4">
      <DesignSytem.P>
        Aucun justificatif de vaccinations en attente
      </DesignSytem.P>
    </DesignSytem.Card>
  );
};

export default Vaccinations;
