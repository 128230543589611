import React from 'react';

import * as DesignSytem from 'src/components/design-system';

import Button from 'src/components/design-system/buttons/action';
import useAxios from 'src/hooks/axios/useAxios';

import routes from 'src/config/routes';

const dataToLines = (data, onClick) =>
  data.map((c) => [
    c.enfant_name,
    <div className="text-right">
      <DesignSytem.Link to={`/admin/familles/${c.famille_id}`}>
        Voir la famille
      </DesignSytem.Link>
      <Button
        mustConfirm
        confirmationMessage={`Êtes vous sur de vouloir valider le certificat médical de ${c.enfant_name}? Le certificat doit être de moins de 3 mois.`}
        onClick={onClick(c.inscription_id)}
        className="ml-8"
      >
        Valider le certificat
      </Button>
    </div>,
  ]);

const Certificats = ({ data, reload }) => {
  const { load: updateCertificat } = useAxios({
    url: routes.fixCertificat(),
    method: 'POST',
  });

  const onClick = (id) => (e) => {
    e.preventDefault();
    updateCertificat({
      payload: { id },
    }).then(reload);
  };

  return data && data.length > 0 ? (
    <DesignSytem.Table lines={dataToLines(data, onClick)} className="mt-4" />
  ) : (
    <DesignSytem.Card className="mt-4">
      <DesignSytem.P>Aucun certificat d'aptitude en attente</DesignSytem.P>
    </DesignSytem.Card>
  );
};

export default Certificats;
