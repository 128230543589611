import React, { useState } from 'react';
import { Link } from 'gatsby';
import * as Icons from 'heroicons-react';
import classnames from 'classnames';

import Logo from 'src/components/logo';

import { MobileMenuItem, DesktopMenuItem } from './admin/menu-items';
import ProfileIcon from './admin/profile-icon';

const navLinks = [
  { label: 'Accueil', to: '/admin/', icon: Icons.HomeOutline },
  { label: 'Saisons', to: '/admin/saisons', icon: Icons.CalendarOutline },
  { label: 'Adhérents', to: '/admin/familles', icon: Icons.UsersOutline },
  { label: 'Dossiers', to: '/admin/dossiers', icon: Icons.FolderOutline },
  { label: 'Communication', to: '/admin/mail', icon: Icons.MailOutline },
  { label: 'Nouvelles', to: '/admin/news', icon: Icons.NewspaperOutline },
  { label: 'Statistiques', to: '/admin/stats', icon: Icons.ChartBarOutline },
  { label: 'Administrateurs', to: '/admin/team', icon: Icons.UserGroupOutline },
  {
    label: 'Configuration',
    to: '/admin/configuration',
    icon: Icons.CogOutline,
  },
];

const AdminLayout = ({ children, userProfile }) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const hideMenu = () => {
    setDisplayMenu(false);
  };
  const showMenu = () => {
    setDisplayMenu(true);
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50">
      {/* Off-canvas menu for mobile */}
      <div className="md:hidden">
        <div
          className={classnames('fixed inset-0 z-40 flex', {
            hidden: !displayMenu,
            block: displayMenu,
          })}
        >
          {/*
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      */}
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
          {/*
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      */}
          <div className="relative flex flex-col flex-1 w-full max-w-xs bg-white">
            <div className="absolute top-0 right-0 p-1 -mr-14">
              <button
                className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
                aria-label="Close sidebar"
                onClick={hideMenu}
              >
                <Icons.X className="w-6 h-6 text-white" />
              </button>
            </div>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <Logo className="w-auto h-8" />
                <h1 className="ml-2 font-thin text-3xl">L'enfant d'eau</h1>
              </div>
              <nav className="px-2 mt-5">
                {navLinks.map((entry) => (
                  <MobileMenuItem
                    {...entry}
                    key={entry.label}
                    onClick={hideMenu}
                  />
                ))}
              </nav>
            </div>
          </div>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </div>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64 bg-white border-r border-gray-200">
          <div className="flex flex-col flex-1 h-0 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Logo className="w-auto h-8" />
              <h1 className="ml-2 font-thin text-3xl">L'enfant d'eau</h1>
            </div>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <nav className="flex-1 px-2 mt-5 bg-white">
              {navLinks.map((entry) => (
                <DesktopMenuItem {...entry} key={entry.label} />
              ))}
            </nav>
          </div>
          <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
            <a href="#" className="flex-shrink-0 block group w-full">
              <div className="flex items-center w-full">
                <div>
                  <ProfileIcon profileName={userProfile.name} />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-700 leading-5 group-hover:text-gray-900 whitespace-pre-wrap">
                    {userProfile.name}
                  </p>
                  <Link
                    className="text-xs font-medium text-gray-500 leading-4 group-hover:text-gray-700 transition ease-in-out duration-150"
                    to="/logout"
                  >
                    Déconnexion
                  </Link>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <div className="pt-1 pl-1 md:hidden sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
            aria-label="Open sidebar"
            onClick={showMenu}
          >
            <Icons.MenuAlt2Outline />
          </button>
        </div>
        <main
          className="relative z-0 flex-1 pt-2 pb-6 overflow-y-auto focus:outline-none md:py-6"
          tabIndex="0"
        >
          <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 mb-8">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
