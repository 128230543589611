import React from 'react';
import classnames from 'classnames';
import { navigate, Link } from 'gatsby';

const UnderlineLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => ({
      className: classnames(
        'w-1/4 px-1 py-4 text-sm font-medium text-center border-b-2 leading-5 focus:outline-none ',
        {
          'text-blue-600 border-blue-500 focus:text-blue-800 focus:border-blue-700': isCurrent,
          'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300': !isCurrent,
        }
      ),
    })}
  />
);

const Tabs = ({ className, links = [], location = {}, ...otherProps }) => {
  const moveTo = (e) => navigate(e.target.value);

  return (
    <div className={classnames('', className)} {...otherProps}>
      <div className="sm:hidden">
        <select
          aria-label="Selected tab"
          onChange={moveTo}
          value={location.pathName}
          className="block w-full form-select"
        >
          {links.map((l) => (
            <option value={l.to}>{l.label}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            {links.map((l) => (
              <UnderlineLink to={l.to}>{l.label}</UnderlineLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
