import React from 'react';
import { Link } from 'gatsby';

import Submit from 'src/components/design-system/buttons/submit';
import ErrorAlert from 'src/components/design-system/alerts/error';
import FormLine from 'src/components/design-system/form/line';
import SimpleInput from 'src/components/design-system/form/input';

import useForm from 'src/hooks/useForm';

const FormSaison = ({
  initialValue = { annee_debut: '', annee_fin: '' },
  axiosConfig,
}) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue,
    axiosConfig,
    redirectUrl: `/admin/saisons`,
  });

  return (
    <form onSubmit={submitForm}>
      <div>
        <div className="border-gray-200">
          <div>
            {requestState.status === 'error' && (
              <ErrorAlert
                className="mt-4"
                message={requestState.error.message}
              />
            )}
          </div>
          <div className="mt-6 sm:mt-5">
            <FormLine
              label="Année de début"
              htmlFor="debut"
              className="sm:border-t"
            >
              <SimpleInput
                id="debut"
                value={formData.annee_debut}
                type="number"
                placeholder={new Date().getFullYear()}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    annee_debut: e.target.value,
                  });
                }}
              />
            </FormLine>
            <FormLine label="Année de fin" htmlFor="fin">
              <SimpleInput
                id="fin"
                type="number"
                value={formData.annee_fin}
                placeholder={new Date().getFullYear() + 1}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    annee_fin: e.target.value,
                  });
                }}
              />
            </FormLine>
          </div>
        </div>
      </div>
      <div className="pt-5 mt-8 border-t border-gray-200">
        <div className="flex justify-end">
          <span className="inline-flex rounded-md shadow-sm">
            <Link
              to="/admin/saisons"
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md leading-5 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
            >
              Retour à la liste
            </Link>
          </span>
          <Submit type="submit" disabled={isFormPristine}>
            Enregistrer
          </Submit>
        </div>
      </div>
    </form>
  );
};

export default FormSaison;
