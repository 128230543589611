import React from 'react';
import qs from 'qs';

import * as DesignSystem from 'src/components/design-system';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import Pagination from 'src/components/design-system/search/pagination';
import { get as getSearchParameters } from 'src/utils/searchParameters';

const getURLWithPage = (p) => {
  const query = {
    page: p,
  };
  return `/admin/news/?${qs.stringify(query)}`;
};

const getLines = (news) =>
  news.map((n) => [
    <DesignSystem.Link to={`/admin/news/${n.id}`}>{n.title}</DesignSystem.Link>,
    n.created_at,
    `${n.author.name} (${n.author.email})`,
  ]);

const ListNews = ({ data: news, meta }) => {
  const { current_page, last_page } = meta;
  return (
    <div>
      <DesignSystem.Header
        title="Nouvelles"
        action={{ label: 'Créer une nouvelle', to: '/admin/news/new' }}
      ></DesignSystem.Header>
      {news.length === 0 ? (
        <DesignSystem.P className="mt-8 text-xl w-full m-auto text-center">
          Pas de nouvelles encore publié
        </DesignSystem.P>
      ) : (
        <>
          <DesignSystem.Table
            headers={['Sujet', 'Date de publication', 'Auteur']}
            lines={getLines(news)}
            className="mt-4"
          />
          <Pagination
            className="mt-4"
            currentPage={current_page}
            lastPage={last_page}
            linkFromPage={getURLWithPage}
          />
        </>
      )}
    </div>
  );
};

const ListNewsWithData = ({ location }) => {
  const searchParameters = getSearchParameters(location, { page: '1' });

  const { requestState } = useAxiosNow({
    url: routes.news(searchParameters),
  });

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rs) => <ListNews {...rs.data} />}
    </DesignSystem.Loading>
  );
};

export default ListNewsWithData;
