import { Link } from 'gatsby';
import React from 'react';

import useForm from '../../../../hooks/useForm';
import ErrorAlert from '../../../design-system/alerts/error';
import Submit from '../../../design-system/buttons/submit';
import DateInput from '../../../design-system/form/date';
import SimpleInput from '../../../design-system/form/input';
import FormLine from '../../../design-system/form/line';
import ToggleInput from '../../../design-system/form/toggle';

import { convertDateFrToISO } from '../../../../utils/date';

const SessionForm = ({
  initialValue,
  axiosConfig,
  saisonId,
  labelSubmit = 'Créer',
}) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue,
    axiosConfig,
    redirectUrl: `/admin/saisons/${saisonId}`,
    formToPayload: (form) => ({
      ...form,
      date_debut_activite: convertDateFrToISO(form.date_debut_activite),
    }),
  });

  const errors =
    requestState.status === 'error' ? requestState.error.errors : {};

  return (
    <form onSubmit={submitForm}>
      <div>
        {requestState.status === 'error' && (
          <ErrorAlert
            className="mt-4"
            message="Un ou plusieurs champs ne sont pas correctement remplis."
          />
        )}
        <div className="border-gray-200">
          <div className="mt-6 sm:mt-5">
            <FormLine
              label="Début d'activité"
              htmlFor="debut"
              className="sm:border-t"
            >
              <DateInput
                id="date_debut_activite"
                required
                value={formData.date_debut_activite}
                errors={errors}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    date_debut_activite: e.target.value,
                  });
                }}
              />
            </FormLine>
            <FormLine
              label="Ouverte?"
              help="Controle si les adhérents peuvent s'inscrire à cette session"
              htmlFor="affichage"
            >
              <ToggleInput
                id="affichage"
                required
                checked={formData.affichage}
                onChange={(checked) => {
                  setFormData({
                    ...formData,
                    affichage: checked,
                  });
                }}
              />
            </FormLine>
            <FormLine label="Adhésion" htmlFor="adhesion">
              <SimpleInput
                id="adhesion"
                required
                type="number"
                errors={errors}
                value={formData.adhesion}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    adhesion: parseFloat(e.target.value),
                  });
                }}
              />
            </FormLine>
            <FormLine label="Premier enfant" htmlFor="enfant1">
              <SimpleInput
                id="enfant1"
                required
                type="number"
                errors={errors}
                value={formData.enfant1}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    enfant1: parseFloat(e.target.value),
                  });
                }}
              />
            </FormLine>
            <FormLine label="Deuxième enfant" htmlFor="enfant2">
              <SimpleInput
                id="enfant2"
                required
                type="number"
                errors={errors}
                value={formData.enfant2}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    enfant2: parseFloat(e.target.value),
                  });
                }}
              />
            </FormLine>
            <FormLine label="Troisième enfant" htmlFor="enfant3">
              <SimpleInput
                id="enfant3"
                required
                type="number"
                value={formData.enfant3}
                errors={errors}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    enfant3: parseFloat(e.target.value),
                  });
                }}
              />
            </FormLine>
          </div>
        </div>
      </div>
      <div className="pt-5 mt-8 border-t border-gray-200">
        <div className="flex justify-end">
          <span className="inline-flex rounded-md shadow-sm">
            <Link
              to={`/admin/saisons/${saisonId}`}
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md leading-5 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
            >
              Retour à la liste
            </Link>
          </span>
          <Submit type="submit" disabled={isFormPristine}>
            {labelSubmit}
          </Submit>
        </div>
      </div>
    </form>
  );
};

export default SessionForm;
