import React from 'react';
import classnames from 'classnames';

const Checkbox = ({
  id,
  name,
  className,
  label,
  help,
  checked,
  onChange,
  ...otherProps
}) => (
  <div className={classnames('flex items-start', className)} {...otherProps}>
    <div className="absolute flex items-center h-5">
      <input
        id={id}
        type="checkbox"
        checked={checked}
        className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
        onChange={onChange}
      />
    </div>
    <div className="pl-7 text-sm leading-5">
      <label htmlFor={id} className="font-medium text-gray-700">
        {label}
      </label>
      {help && <p className="text-gray-500">{help}</p>}
    </div>
  </div>
);

export default Checkbox;
