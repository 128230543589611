import React from 'react';
import qs from 'qs';

import * as DesignSystem from 'src/components/design-system';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import Pagination from 'src/components/design-system/search/pagination';

import { get as getSearchParameters } from 'src/utils/searchParameters';

const getURLWithPage = (p) => {
  const query = {
    page: p,
  };
  return `/admin/mail/?${qs.stringify(query)}`;
};

const translateStatus = (status) => {
  if (status === 'done') return 'Envoyé';
  else if (status === 'in progress') return 'En cours';
  else return status;
};

const getLines = (mailings) =>
  mailings.map((m) => [
    m.created_at,
    m.subject,
    m.emails.length,
    translateStatus(m.status),
    <DesignSystem.Link to={`/admin/mail/view/${m.id}`}>Voir</DesignSystem.Link>,
  ]);

const MailLog = ({ data: mailings, meta }) => {
  const { current_page, last_page } = meta;
  return (
    <div>
      <DesignSystem.Header
        title="Communications aux adhérents"
        action={{ label: 'Envoyer un message', to: '/admin/mail/send' }}
      ></DesignSystem.Header>
      {mailings.length === 0 ? (
        <DesignSystem.P className="mt-8 text-xl w-full m-auto text-center">
          Pas de mail encore envoyé
        </DesignSystem.P>
      ) : (
        <>
          <DesignSystem.Table
            headers={['Créé le', 'Sujet', 'Destinataires', 'Statut', '']}
            lines={getLines(mailings)}
            className="mt-4"
          />
          <Pagination
            className="mt-4"
            currentPage={current_page}
            lastPage={last_page}
            linkFromPage={getURLWithPage}
          />
        </>
      )}
    </div>
  );
};

const MailLogWithData = ({ location }) => {
  const searchParameters = getSearchParameters(location, { page: '1' });

  const { requestState } = useAxiosNow({
    url: routes.mailings(searchParameters),
  });

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rs) => <MailLog {...rs.data} location={location} />}
    </DesignSystem.Loading>
  );
};

export default MailLogWithData;
