import React from 'react';
import * as DesignSystem from 'src/components/design-system';
import ErrorAlert from 'src/components/design-system/alerts/error';
import CancelButton from 'src/components/design-system/buttons/cancel';
import Submit from 'src/components/design-system/buttons/submit';
import * as Form from 'src/components/design-system/form';
import routes from 'src/config/routes';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import useForm from 'src/hooks/useForm';
import { formatFamilyName } from 'src/utils/famille';
import { formatSaison } from 'src/utils/saison';

const PaiementForm = ({
  paiement = {},
  axiosConfig = { method: 'put', url: '' },
}) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
    errors,
    isLoading,
  } = useForm({
    initialValue: paiement,
    axiosConfig,
    redirectUrl: `/admin/familles/${paiement.familles_id}`,
  });

  const updateTextField = (fieldName) => (e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  return (
    <Form.Card
      onSubmit={submitForm}
      className="mt-4"
      title={`Famille ${formatFamilyName(
        paiement.famille
      )} / Saison ${formatSaison(paiement.saison)}`}
    >
      <div>
        {requestState.status === 'error' && (
          <ErrorAlert className="mt-4" message={requestState.error.message} />
        )}
      </div>
      <Form.Line label="Montant payé" htmlFor="prix_paye">
        <Form.Input
          id="prix_paye"
          value={formData.prix_paye}
          onChange={updateTextField('prix_paye')}
          errors={errors}
        />
      </Form.Line>
      <Form.Line label="Montant total" htmlFor="prix_a_payer">
        <Form.Input
          id="prix_a_payer"
          value={formData.prix_a_payer}
          onChange={updateTextField('prix_a_payer')}
          errors={errors}
        />
      </Form.Line>
      <Form.Line label="Mode de paiement" htmlFor="XXX">
        <Form.Select
          id="mode_paiement"
          value={formData.mode_paiement}
          onChange={updateTextField('mode_paiement')}
          errors={errors}
        >
          <option value="CB">Carte bancaire</option>
          <option value="cheque">Virement / autre moyen</option>
        </Form.Select>
      </Form.Line>
      <Form.Line
        label="Référence du paiement"
        htmlFor="ref_paiement"
        help="Sert à tracer le moyen de paiement. En cas de paiement par carte, il s'agit d'un identifiant de paiement Stripe. Sinon cela peut être tout autre information, à la discrétion du membre du CA qui récupère le paiement (date de vérification du virement, référence)"
      >
        <Form.Input
          id="ref_paiement"
          value={formData.ref_paiement}
          onChange={updateTextField('ref_paiement')}
          errors={errors}
        />
      </Form.Line>
      <Form.Line
        label="Réduction"
        htmlFor="reduction_50"
        help="Indique si la famille bénéficie d'une réduction et si elle a présenté les justificatifs"
      >
        <Form.Select
          id="reduction_50"
          value={formData.reduction_50}
          onChange={updateTextField('reduction_50')}
          errors={errors}
        >
          <option value="">Pas de réduction</option>
          <option value="non">Justificatifs non fournis</option>
          <option value="oui">Justificatifs fournis</option>
        </Form.Select>
      </Form.Line>
      <Form.Line label="Statut du paiement" htmlFor="statut">
        <Form.Select
          id="statut"
          value={formData.statut}
          onChange={updateTextField('statut')}
          errors={errors}
        >
          <option value="">N/A</option>
          <option value="ok">Ok</option>
          <option value="en cours">En cours</option>
        </Form.Select>
      </Form.Line>
      <Form.Footer>
        <CancelButton to={`/admin/familles/${paiement.familles_id}`}>
          Retour à la liste
        </CancelButton>
        <Submit type="submit" disabled={isFormPristine} isLoading={isLoading}>
          Mettre à jour
        </Submit>
      </Form.Footer>
    </Form.Card>
  );
};

const EditPaiement = ({ paiementId, familleId }) => {
  const { requestState } = useAxiosNow({
    url: routes.paiement(paiementId),
  });

  return (
    <>
      <DesignSystem.Header
        title="Paiement"
        back={{
          to: `/admin/familles/${familleId}`,
          label: 'Retour à la famille',
        }}
      />
      <DesignSystem.Loading requestState={requestState}>
        {(rs) => (
          <PaiementForm
            paiement={rs.data.data}
            axiosConfig={{ method: 'put', url: routes.paiement(paiementId) }}
          />
        )}
      </DesignSystem.Loading>
    </>
  );
};

export default EditPaiement;
