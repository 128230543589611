import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import classnames from 'classnames';

import useAxios from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import * as DesignSystem from 'src/components/design-system';
import Tabs from 'src/components/design-system/tab/admin';

import Certificats from './certificats';
import Vaccinations from './vaccinations';
import Reduction from './reduction';

const Issues = ({ className, location, ...otherProps }) => {
  const { requestState, load } = useAxios({
    url: routes.issues(),
  });

  useEffect(() => {
    load();
  }, []);

  return (
    <div className={classnames('w-full', className)} {...otherProps}>
      <DesignSystem.Header title="Dossiers ouverts" />
      <DesignSystem.Loading requestState={requestState}>
        {(rs) => {
          const issues = rs.data.data || {};
          return (
            <div>
              <Tabs
                links={[
                  { label: "Certificats d'aptitude", to: '/admin/dossiers' },
                  {
                    label: 'Vaccinations',
                    to: '/admin/dossiers/vaccins',
                  },
                  {
                    label: 'Réductions',
                    to: '/admin/dossiers/reductions',
                  },
                ]}
              />
              <Router basepath="/admin/dossiers">
                <Certificats data={issues.certificats} path="/" reload={load} />
                <Vaccinations
                  data={issues.vaccins}
                  path="/vaccins"
                  reload={load}
                />
                <Reduction
                  data={issues.reduc}
                  path="/reductions"
                  reload={load}
                />
              </Router>
            </div>
          );
        }}
      </DesignSystem.Loading>
    </div>
  );
};

export default Issues;
