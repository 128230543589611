import React from 'react';
import * as Form from 'src/components/design-system/form';

const SessionFilterLine = ({ sessions, setFormData, formData }) => {
  const toggle = (id) => () => {
    const pos = formData.sessions ? formData.sessions.indexOf(id) : null;
    let newSelection;

    if (pos === null) newSelection = [id];
    else {
      newSelection = [...formData.sessions];
      if (pos === -1) newSelection.push(id);
      else {
        newSelection.splice(pos);
      }
    }

    setFormData({
      ...formData,
      sessions: newSelection,
    });
  };

  const isChecked = (id) =>
    formData.sessions ? formData.sessions.indexOf(id) !== -1 : false;

  return (
    <Form.Line label="Choix des sessions">
      {sessions.map((s) => (
        <Form.Checkbox
          label={`Début le ${s.date_debut_activite}`}
          key={s.ref}
          checked={isChecked(s.id)}
          id={s.id}
          name="sessions"
          onChange={toggle(s.id)}
        />
      ))}
    </Form.Line>
  );
};

export default SessionFilterLine;
