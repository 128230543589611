import React from 'react';
import classnames from 'classnames';
import { findError } from 'src/utils/errors';

const SimpleInput = ({
  className,
  id,
  type,
  value,
  onChange,
  placeholder,
  required = false,
  requestState = {},
  errors,
  ...otherProps
}) => {
  const error = errors ? errors[id] : findError(requestState, id);
  return (
    <div className={classnames('max-w-sm', className)} {...otherProps}>
      <div className="relative rounded-md shadow-sm">
        <input
          id={id}
          className={classnames(
            'block w-full form-input transition duration-150 ease-in-out sm:text-sm sm:leading-5',
            {}
          )}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
      {error && error.map((e) => <p class="mt-2 text-sm text-red-600">{e}</p>)}
    </div>
  );
};

export default SimpleInput;
