import React from 'react';
import classnames from 'classnames';
import Cleave from 'cleave.js/react';
import findError from 'src/utils/errors';

const TimeInput = ({
  className,
  id,
  value,
  onChange,
  errors,
  ...otherProps
}) => {
  const error = errors && errors[id];
  return (
    <div
      className={classnames('max-w-sm rounded-md shadow-sm', className)}
      {...otherProps}
    >
      <Cleave
        id={id}
        className="block w-full form-input transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        options={{
          time: true,
          timePattern: ['h', 'm'],
          timeFormat: '24',
        }}
        value={value}
        placeholder="13:45"
        onChange={onChange}
      />
      {error && error.map((e) => <p class="mt-2 text-sm text-red-600">{e}</p>)}
    </div>
  );
};

export default TimeInput;
