import React from 'react';
import classnames from 'classnames';

import Header from 'src/components/design-system/header';
import Link from 'src/components/design-system/link';
import Loading from 'src/components/design-system/loading';
import Table from 'src/components/design-system/table';
import routes from 'src/config/routes';
import { useAxiosNow } from 'src/hooks/axios/useAxios';

const ListSaisons = () => {
  const { requestState } = useAxiosNow({ url: routes.saisons() });

  return (
    <div>
      <Header
        title="Saisons"
        action={{
          label: 'Créer une nouvelle saison',
          to: '/admin/saisons/new',
        }}
      />
      <Loading requestState={requestState}>
        {(requestState) => {
          const lines = requestState.data.data.map((saison) => [
            <Link key="" to={`/admin/saisons/${saison.id}`}>
              {saison.annee_debut} – {saison.annee_fin}
            </Link>,
            <span
              className={classnames({
                'text-green-500': saison.next,
                'text-transparent': !saison.next,
              })}
            >
              {saison.next ? 'oui' : 'non'}
            </span>,
            <span
              className={classnames({
                'text-green-500': saison.current,
                'text-transparent': !saison.current,
              })}
            >
              {saison.current ? 'oui' : 'non'}
            </span>,
            <span
              className={classnames({
                'text-green-500': saison.previous,
                'text-transparent': !saison.previous,
              })}
            >
              {saison.previous ? 'oui' : 'non'}
            </span>,
            <Link
              key=""
              className="block w-full text-right"
              to={`/admin/saisons/${saison.id}/edit`}
            >
              Editer
            </Link>,
          ]);

          return (
            <Table
              className="pt-4"
              headers={['Saison', 'Prochaine', 'Actuelle', 'Précédente', '']}
              lines={lines}
            />
          );
        }}
      </Loading>
    </div>
  );
};

export default ListSaisons;
