import React from 'react';
import classnames from 'classnames';

const Select = ({
  className,
  children,
  id,
  onChange,
  value,
  ariaLabel,
  errors,
  ...otherProps
}) => {
  const error = errors && errors[id];
  return (
    <>
      <div
        className={classnames('max-w-sm rounded-md shadow-sm', className)}
        {...otherProps}
      >
        <select
          id={id}
          value={value}
          onChange={onChange}
          aria-label={ariaLabel}
          className="block w-full form-select transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        >
          {children}
        </select>
      </div>
      {error && error.map((e) => <p class="mt-2 text-sm text-red-600">{e}</p>)}
    </>
  );
};

export default Select;
