import React from 'react';
import { Router } from '@reach/router';

import ListNews from 'src/components/admin/news/list';
import AddNews from 'src/components/admin/news/add';
import EditNews from 'src/components/admin/news/edit';
import ViewNews from 'src/components/admin/news/view';

const NewsRoutes = () => (
  <Router>
    <ListNews path="/" />
    <AddNews path="/new" />
    <EditNews path="/:id/edit" />
    <ViewNews path="/:id" />
  </Router>
);

export default NewsRoutes;
