import React from 'react';

import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import * as DesignSystem from 'src/components/design-system';
import Form from './form';

const EditNews = ({ id }) => {
  const { requestState } = useAxiosNow({
    url: routes.new(id),
  });

  return (
    <DesignSystem.Card title="Éditer une nouvelle">
      <DesignSystem.Loading requestState={requestState}>
        {(rs) => (
          <Form
            labelSubmit="Mettre à jour"
            initialValue={rs.data.data}
            axiosConfig={{
              method: 'PUT',
              url: routes.new(id),
            }}
          />
        )}
      </DesignSystem.Loading>
    </DesignSystem.Card>
  );
};

export default EditNews;
