import React from 'react';
import classnames from 'classnames';

const TextArea = ({
  className,
  id,
  value,
  onChange,
  placeholder,
  errors,
  ...otherProps
}) => {
  const error = errors && errors[id];
  return (
    <div
      className={classnames('max-w-sm rounded-md shadow-sm', className)}
      {...otherProps}
    >
      <textarea
        id={id}
        className="block w-full form-textarea transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && error.map((e) => <p class="mt-2 text-sm text-red-600">{e}</p>)}
    </div>
  );
};

export default TextArea;
