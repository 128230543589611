import React from 'react';
import classnames from 'classnames';

import useAxios from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import { TailSpin as Loader } from 'svg-loaders-react';

const ResetPasswordButton = ({ email, className, children, ...otherProps }) => {
  const { requestState, load } = useAxios({
    method: 'POST',
    url: routes.resetStep1(),
  });

  const sendResetLink = () => {
    load({ payload: { email } });
  };

  return (
    <span
      className={classnames('inline-flex rounded-md shadow-sm', className)}
      {...otherProps}
    >
      <button
        type="button"
        className={classnames(
          'inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white focus:outline-none transition ease-in-out duration-150',
          {
            'bg-cool-gray-400':
              requestState.status === 'loaded' ||
              requestState.status === 'error',
            'bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red active:bg-red-700':
              requestState.status !== 'loaded' &&
              requestState.status !== 'error',
          }
        )}
        onClick={sendResetLink}
        disabled={
          requestState.status === 'loading' ||
          requestState.status === 'loaded' ||
          requestState.status === 'error'
        }
      >
        Reinitialiser le mot de passe
        {requestState.status === 'loading' && <Loader className="h-4 w-4" />}
        {requestState.status === 'loaded' && (
          <svg fill="currentColor" viewBox="0 0 20 20" className="h-4 w-4 ml-2">
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        )}
        {requestState.status === 'error' && (
          <svg fill="currentColor" viewBox="0 0 20 20" className="ml-2 h-4 w-4">
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            ></path>
          </svg>
        )}
      </button>
    </span>
  );
};

export default ResetPasswordButton;
