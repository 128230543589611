import React from 'react';
import * as DesignSystem from 'src/components/design-system';
import * as Form from 'src/components/design-system/form';
import ActionButton from 'src/components/design-system/buttons/action';

import routes from 'src/config/routes';
import { useAxiosNow } from 'src/hooks/axios/useAxios';

import CreneauFilterLine from './creneaux';
import SessionFilterLine from './sessions';
import SaisonFilterLine from './saison';
import IssueFilterLine from './issue';

const filterTypes = [
  { label: 'Créneaux', value: 'creneaux' },
  { label: 'Sessions', value: 'sessions' },
  { label: 'Dossiers incomplets', value: 'issues' },
  { label: 'Par saison', value: 'saison' },
];

const noValueSelected = (formData) =>
  !formData.filterType ||
  (formData.filterType === 'creneaux' &&
    (!formData.creneaux || formData.creneaux.length === 0)) ||
  (formData.filterType === 'sessions' &&
    (!formData.sessions || formData.sessions.length === 0)) ||
  (formData.filterType === 'saison' && !formData.saison) ||
  (formData.filterType === 'issues' && !formData.issue);

const Filters = ({
  formData,
  setFormData,
  creneaux,
  sessions,
  saisons,
  issues,
}) => {
  return (
    <DesignSystem.Card title="Choix des filtres" className="mt-4">
      <Form.Line
        label="Filtre"
        help="Ceci permet de choisir comment vous voulez recherchez les utilisateurs à qui vous voulez envoyer un message"
        htmlFor="filterType"
      >
        <Form.Select
          id="filterType"
          value={formData.filterType}
          onChange={(e) =>
            setFormData({ ...formData, filterType: e.target.value })
          }
        >
          <option value="">Pas de type de filtre sélectionné</option>
          {filterTypes.map((f) => (
            <option value={f.value} key={f.value}>
              {f.label}
            </option>
          ))}
        </Form.Select>
      </Form.Line>

      {formData.filterType && formData.filterType === 'creneaux' && (
        <CreneauFilterLine
          creneaux={creneaux}
          formData={formData}
          setFormData={setFormData}
        />
      )}

      {formData.filterType && formData.filterType === 'sessions' && (
        <SessionFilterLine
          sessions={sessions}
          formData={formData}
          setFormData={setFormData}
        />
      )}

      {formData.filterType && formData.filterType === 'issues' && (
        <IssueFilterLine
          issues={issues}
          formData={formData}
          setFormData={setFormData}
        />
      )}

      {formData.filterType && formData.filterType === 'saison' && (
        <SaisonFilterLine
          saisons={saisons}
          formData={formData}
          setFormData={setFormData}
        />
      )}

      <Form.Footer>
        <ActionButton
          to="/admin/mail/send/adherents"
          disabled={noValueSelected(formData)}
        >
          Choisir les destinataires
        </ActionButton>
      </Form.Footer>
    </DesignSystem.Card>
  );
};

const FiltersWithData = ({ formData, setFormData }) => {
  const { requestState } = useAxiosNow({
    url: routes.mailingFilters(),
  });

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rsFilters) => {
        const fs = rsFilters.data.data;
        return (
          <Filters {...fs} formData={formData} setFormData={setFormData} />
        );
      }}
    </DesignSystem.Loading>
  );
};

export default FiltersWithData;
