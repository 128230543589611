import React from 'react';
import * as DesignSystem from 'src/components/design-system';
import { convertDateISOToFr } from 'src/utils/date';

const formatReduc = (reduc) => {
  if (!reduc) return 'non';
  if (reduc === 'oui')
    return <span className="text-green-300">Justificatifs fournis</span>;
  if (reduc === 'non')
    return <span className="text-red-500">Justificatifs non fournis</span>;
};

const getPaiementLines = (paiements) =>
  paiements.map((p) => [
    convertDateISOToFr(p.created_at),
    p.prix_paye,
    p.prix_a_payer,
    p.mode_paiement,
    p.statut,
    formatReduc(p.reduction_50),
    <DesignSystem.Link
      to={`/admin/familles/${p.familles_id}/paiement/${p.id}`}
      className="block text-right"
    >
      Mettre à jour
    </DesignSystem.Link>,
  ]);

const Paiements = ({ famille }) =>
  famille.paiements && famille.paiements.length > 0 ? (
    <DesignSystem.Table
      headers={[
        'Date',
        'Prix payé',
        'Total',
        'Mode',
        'Statut',
        'Reduction?',
        '',
      ]}
      lines={getPaiementLines(famille.paiements)}
      className="mt-4 w-full"
    />
  ) : (
    <DesignSystem.P>Pas de paiement trouvé</DesignSystem.P>
  );

export default Paiements;
