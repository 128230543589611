import React from 'react';
import classnames from 'classnames';

import * as ContentDesign from 'src/components/layouts/content/theme';
import remark from 'remark';
import remarkReact from 'remark-react';

const Markdown = ({ className, children, ...otherProps }) => (
  <div className={classnames('', className)} {...otherProps}>
    {
      remark()
        .use(remarkReact, {
          remarkReactComponents: {
            h2: ContentDesign.H2,
            h3: ContentDesign.H3,
            p: ContentDesign.P,
            ul: ContentDesign.List,
            table: ContentDesign.TableMd,
            a: ContentDesign.A,
            code: ContentDesign.Code,
          },
        })
        .processSync(children).result
    }
  </div>
);

export default Markdown;
