import classnames from 'classnames';
import React from 'react';

import Select from 'src/components/design-system/form/select';

const SelectFilterSet = ({
  className,
  value,
  options,
  onChange = () => {},
  ...otherProps
}) => {
  return (
    <div className={classnames('w-full', className)} {...otherProps}>
      <Select
        className="w-full pt-4 sm:hidden"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((o) => (
          <option value={o.value}>{o.label}</option>
        ))}
      </Select>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            {options.map((o) => {
              if (o.value === value) {
                return (
                  <button
                    className="w-1/4 px-1 py-4 text-sm font-medium text-center text-blue-600 border-b-2 border-blue-500 leading-5 focus:outline-none focus:text-blue-800 focus:border-blue-700"
                    aria-current="page"
                  >
                    {o.label}
                  </button>
                );
              } else {
                return (
                  <button
                    onClick={() => onChange(o.value)}
                    className="w-1/4 px-1 py-4 text-sm font-medium text-center text-gray-500 border-b-2 border-transparent leading-5 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                  >
                    {o.label}
                  </button>
                );
              }
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SelectFilterSet;
