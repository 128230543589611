import React from 'react';
import Strong from 'src/components/design-system/strong';

import { SecondaryTable } from 'src/components/design-system/table';

const PopStats = ({ data }) => (
  <>
    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 className="text-lg font-medium text-gray-900 leading-6">
        Informations adhérents
      </h3>
      <p className="max-w-2xl mt-1 text-sm text-gray-500 leading-5">
        Qui sont les adhérents et leurs enfants?
      </p>
    </div>
    <div className="px-4 py-5 sm:p-0">
      <dl>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm font-medium text-gray-500 leading-5">
            Nouveaux enfants
            <div className="font-light">
              Première inscription durant cette saison
            </div>
          </dt>
          <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
            {data.enfantsByInscriptionNb && (
              <>
                <Strong>{data.enfantsByInscriptionNb['1']}</Strong>
                {' soit '}
                <Strong>
                  {(
                    (100 * data.enfantsByInscriptionNb['1']) /
                    data.totalEnfants
                  ).toFixed(2)}
                  %
                </Strong>{' '}
                des enfants inscrits
              </>
            )}
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm font-medium text-gray-500 leading-5">
            Nouveaux adhérents
            <div className="font-light">
              Familles dont c'est la première année
            </div>
          </dt>
          <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
            {data.famillesByInscriptionNb && (
              <>
                <Strong>{data.famillesByInscriptionNb['1']}</Strong>
                {' soit '}
                <Strong>
                  {(
                    (100 * data.famillesByInscriptionNb['1']) /
                    data.totalFamilles
                  ).toFixed(2)}
                  %
                </Strong>{' '}
                des familles inscrites
              </>
            )}
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm font-medium text-gray-500 leading-5">
            Parité enfants
            <div className="font-light">Répartition filles / garçons</div>
          </dt>
          <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
            {data.enfantsBySex.fille} filles (
            {(100 * (data.enfantsBySex.fille / data.totalEnfants)).toFixed(2)}%)
            et {data.enfantsBySex.garcon} garçons (
            {(100 * (data.enfantsBySex.garcon / data.totalEnfants)).toFixed(2)}
            %)
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm font-medium text-gray-500 leading-5">
            Composition des familles
            <div className="font-light">
              Combien d'enfants inscrits par familles
            </div>
          </dt>
          <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
            <SecondaryTable
              headers={Object.keys(data.compositionFamilles).map(
                (v) => `${v} enfant${v === '1' ? '' : 's'}`
              )}
              lines={[Object.values(data.compositionFamilles)]}
            />
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm font-medium text-gray-500 leading-5">
            Nombre d'adhérents beneficiant de la réduction
            <div className="font-light"></div>
          </dt>
          <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
            {data.reduction_50_OK + data.reduction_50_KO} soit{' '}
            {(
              (100 * (data.reduction_50_OK + data.reduction_50_KO)) /
              data.totalFamilles
            ).toFixed(2)}
            % des adhérents
          </dd>
        </div>
      </dl>
    </div>
  </>
);

export default PopStats;
