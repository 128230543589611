import React from 'react';
const MainStats = ({ data }) => (
  <>
    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Dossiers problématiques
      </h3>
      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500"></p>
    </div>
    <div className="px-4 py-5 sm:p-0">
      <dl>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Nombre d'adhérents avec un paiement non régularisé
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {data.paiementNotPaid.count +
              data.paiementOverPaid.count +
              data.paiementMissing.count}{' '}
            familles soit{' '}
            {(
              (100 *
                (data.paiementNotPaid.count +
                  data.paiementOverPaid.count +
                  data.paiementMissing.count)) /
              data.totalFamilles
            ).toFixed(2)}
            %
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Justificatif de réduction manquant
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {data.reduction_50_KO} familles soit{' '}
            {(
              (100 * data.reduction_50_KO) /
              (data.reduction_50_KO + data.reduction_50_OK)
            ).toFixed(2)}
            %
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Certificat médical manquant
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {data.certificats_medicaux['0']} soit{' '}
            {(
              (100 * data.certificats_medicaux['0']) /
              (data.certificats_medicaux['0'] + data.certificats_medicaux['1'])
            ).toFixed(2)}
            % des familles
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Preuve de vaccination manquante
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {data.vaccination['non']} soit{' '}
            {(
              (100 * data.vaccination['non']) /
              (data.vaccination['non'] +
                data.vaccination['carnet'] +
                data.vaccination['certificat'])
            ).toFixed(2)}
            % des familles
          </dd>
        </div>
      </dl>
    </div>
  </>
);

export default MainStats;
