import React from 'react';
import classnames from 'classnames';

const FormSection = ({ className, children, ...otherProps }) => (
  <div className={classnames('mt-6 px-2 md:px-0', className)} {...otherProps}>
    <h3 className="text-lg leading-6 font-medium text-gray-900 pt-4">
      {children}
    </h3>
  </div>
);

export default FormSection;
