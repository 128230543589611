import React from 'react';
import * as DesignSystem from 'src/components/design-system';
import { convertDateISOToFr } from 'src/utils/date';

const getInscriptionLine = (inscriptions, saisonCouranteId) =>
  inscriptions.map((i) => [
    <>
      {i.saison.annee_debut} {i.saison.annee_fin}
    </>,
    <div>
      {i.creneau.ref} - {i.creneau.intitule}
    </div>,
    <div>{convertDateISOToFr(i.session.date_debut_activite)}</div>,
    i.certificat_medical_fourni ? (
      <div className="text-right">
        Fourni{' '}
        {i.date_certificat_medical &&
          `le ${convertDateISOToFr(i.date_certificat_medical)}`}
      </div>
    ) : (
      <div className="text-right text-red-500">Non fourni</div>
    ),
  ]);

const Enfant = ({ enfant, famille }) => {
  if (!enfant.inscriptions || enfant.inscriptions.length === 0) {
    return <DesignSystem.P>Pas d'inscription pour le moment</DesignSystem.P>;
  }

  return (
    <DesignSystem.Card className="mt-4">
      <div className="flex flex-col md:flex-row md:items-end">
        <div className="flex flex-row items-baseline flex-grow">
          <DesignSystem.H3>
            {enfant.prenom} {enfant.nom}
          </DesignSystem.H3>

          <DesignSystem.Link
            to={`/admin/familles/${famille.id}/enfant/${enfant.id}`}
            className="ml-2"
          >
            Mettre à jour
          </DesignSystem.Link>
        </div>
        <div className="flex flex-row mt-2 md:mt-0 ">
          Vaccination
          <div className="ml-2">
            {enfant.vaccination === 'non' && (
              <span className="text-red-800">justificatifs manquants</span>
            )}
            {enfant.vaccination === 'carnet' && (
              <span className="text-green-500">carnet vu</span>
            )}
            {enfant.vaccination === 'certificat' && (
              <span className="text-green-500">certificat fourni</span>
            )}
          </div>
        </div>
      </div>
      <DesignSystem.Table
        headers={[
          'Saison',
          'Créneau',
          'Session',
          <div className="text-right">Certificat</div>,
        ]}
        lines={getInscriptionLine(enfant.inscriptions)}
        className="w-full mt-4"
      />
    </DesignSystem.Card>
  );
};

const Enfants = ({ famille }) => (
  <div>
    {famille.enfants && famille.enfants.length > 0
      ? famille.enfants.map((e) => <Enfant enfant={e} famille={famille} />)
      : "Pas d'enfants dans la base"}
  </div>
);

export default Enfants;
