import React from 'react';
import * as DesignSystem from 'src/components/design-system';
import InfoAlert from 'src/components/design-system/alerts/info';
import routes from 'src/config/routes';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import { convertDateISOToFr } from 'src/utils/date';
import EnfantForm from './form';
import InscriptionForm from './inscriptionForm';

const getCurrentInscription = (enfant) => {
  if (enfant && enfant.currentYearInscription) {
    return enfant.currentYearInscription;
  }
  return undefined;
};

const getNextInscription = (enfant) => {
  if (enfant && enfant.nextYearInscription) {
    return enfant.nextYearInscription;
  }
  return undefined;
};

const EditEnfant = ({ familleId, id }) => {
  const { requestState } = useAxiosNow({
    url: routes.enfant(id),
  });

  return (
    <>
      <DesignSystem.Header
        title="Mise à jour"
        back={{
          to: `/admin/familles/${familleId}`,
          label: 'Retour à la famille',
        }}
      />
      <DesignSystem.Loading requestState={requestState}>
        {(rs) => {
          const data = rs.data.data;
          data.date_naissance = convertDateISOToFr(data.date_naissance);

          const currentYearInscription = getCurrentInscription(data);
          const nextYearInscription = getNextInscription(data);

          return (
            <>
              <EnfantForm
                initialValue={data}
                axiosConfig={{
                  method: 'PUT',
                  url: routes.enfant(id),
                }}
                familleId={familleId}
                labelSubmit="Mettre à jour"
              />

              {currentYearInscription ? (
                <InscriptionForm
                  enfant={data}
                  creneaux={rs.data.meta.creneaux}
                  familleId={familleId}
                  inscription={currentYearInscription}
                />
              ) : (
                <InfoAlert
                  message="Cet enfant n'est pas inscrit pour l'année en cours."
                  className="mt-4"
                />
              )}

              {nextYearInscription &&
              nextYearInscription.id !== currentYearInscription.id ? (
                <InscriptionForm
                  enfant={data}
                  creneaux={rs.data.meta.nextYearCreneaux}
                  familleId={familleId}
                  inscription={nextYearInscription}
                />
              ) : (
                <InfoAlert
                  message="Cet enfant n'est pas inscrit pour l'année prochaine"
                  className="mt-4"
                />
              )}
            </>
          );
        }}
      </DesignSystem.Loading>
    </>
  );
};

export default EditEnfant;
