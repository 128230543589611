import React from 'react';
import { navigate } from 'gatsby';

import ErrorAlert from 'src/components/design-system/alerts/error';
import Header from 'src/components/design-system/header';
import Loading from 'src/components/design-system/loading';
import routes from 'src/config/routes';
import useAxios, { isLoading, useAxiosNow } from 'src/hooks/axios/useAxios';
import { convertDateISOToFr } from 'src/utils/date';

import SessionForm from './form';

const EditSession = ({ sessionId, saisonId }) => {
  const { requestState } = useAxiosNow({
    url: routes.session(sessionId),
  });

  const { requestState: rsDelete, load: deleteCreneau } = useAxios({
    method: 'delete',
    url: routes.session(sessionId),
  });

  return (
    <>
      <Header
        title="Éditer la session"
        action={{
          label: 'Supprimer',
          onClick: (e) => {
            e.preventDefault();
            deleteCreneau().then(({ error }) => {
              if (!error) navigate(`/admin/saisons/${saisonId}`);
            });
          },
          color: 'red',
          mustConfirm: true,
          isLoading: isLoading(rsDelete),
          confirmationMessage:
            'Êtes vous sur de vouloir supprimer cette session?',
        }}
      >
        {rsDelete.status === 'error' && (
          <ErrorAlert
            className="mt-4 w-full"
            message={rsDelete.error.message}
          />
        )}
      </Header>
      <Loading requestState={requestState}>
        {(rs) => {
          const data = rs.data.data;
          return (
            <SessionForm
              initialValue={{
                ...data,
                date_debut_activite: convertDateISOToFr(
                  data.date_debut_activite
                ),
              }}
              axiosConfig={{
                method: 'PUT',
                url: routes.session(sessionId),
              }}
              saisonId={saisonId}
              labelSubmit="Valider"
            />
          );
        }}
      </Loading>
    </>
  );
};

export default EditSession;
