import React from 'react';

import routes from 'src/config/routes';
import Header from 'src/components/design-system/header';

import SessionForm from './form';

const AddSession = ({ saisonId }) => {
  return (
    <>
      <Header title="Ajouter une session" />
      <SessionForm
        initialValue={{
          saison_id: saisonId,
          date_debut_activite: '',
          affichage: false,
          position: 0,
          adhesion: 0,
          enfant1: 0,
          enfant2: 0,
          enfant3: 0,
        }}
        axiosConfig={{
          method: 'POST',
          url: routes.sessions(),
        }}
        saisonId={saisonId}
      />
    </>
  );
};

export default AddSession;
