import React, { useEffect } from 'react';

import useForm from 'src/hooks/useForm';
import useAxios from 'src/hooks/axios/useAxios';

import routes from 'src/config/routes';
import ErrorAlert from 'src/components/design-system/alerts/error';
import CancelButton from 'src/components/design-system/buttons/cancel';
import Submit from 'src/components/design-system/buttons/submit';
import FormFooter from 'src/components/design-system/form/footer';
import FormLine from 'src/components/design-system/form/line';
import FormSection from 'src/components/design-system/form/section';
import ToggleInput from 'src/components/design-system/form/toggle';
import Select from 'src/components/design-system/form/select';
import Loading from 'src/components/design-system/loading';
import H1 from 'src/components/design-system/h1';

import Card from 'src/components/design-system/card';

const ConfigurationEditForm = ({ initialValue, saisons }) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue,
    axiosConfig: {
      method: 'PUT',
      url: routes.configuration(initialValue.id),
    },
    formToPayload: (i) => i,
    redirectUrl: `/admin/`,
  });

  return (
    <form onSubmit={submitForm}>
      <div>
        {requestState.status === 'error' && (
          <ErrorAlert className="mt-4" message={requestState.error.message} />
        )}
        <div className="border-gray-200">
          <div className="mt-6 sm:mt-5">
            <FormSection>Gestion des saisons</FormSection>
            <FormLine label="Saison en cours" htmlFor="saison_courante">
              <Select
                id="saison_courante"
                required
                value={formData.saison_courante}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    saison_courante: e.target.value,
                  });
                }}
              >
                <option value="">Pas de saison sélectionnée</option>
                {saisons &&
                  saisons.map((s) => (
                    <option value={s.id}>
                      {s.annee_debut} - {s.annee_fin}
                    </option>
                  ))}
              </Select>
            </FormLine>
            <FormLine
              label="Prochaine saison"
              help="Saison pour laquelle les nouvelles inscriptions sont ouvertes"
              htmlFor="saison_prochaine"
            >
              <Select
                id="saison_prochaine"
                required
                value={formData.saison_prochaine}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    saison_prochaine: e.target.value,
                  });
                }}
              >
                <option value="">Pas de saison sélectionnée</option>
                {saisons &&
                  saisons.map((s) => (
                    <option value={s.id}>
                      {s.annee_debut} - {s.annee_fin}
                    </option>
                  ))}
              </Select>
            </FormLine>
            <FormLine
              label="Saison précédente"
              help="Cette information permet de limiter les reinscriptions aux adhérents inscrits l'année précédente."
              htmlFor="saison_precedente"
            >
              <Select
                id="saison_precedente"
                required
                value={formData.saison_precedente}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    saison_precedente: e.target.value,
                  });
                }}
              >
                <option value="">Pas de saison sélectionnée</option>
                {saisons &&
                  saisons.map((s) => (
                    <option value={s.id}>
                      {s.annee_debut} - {s.annee_fin}
                    </option>
                  ))}
              </Select>
            </FormLine>
            <FormSection>Ouverture des inscriptions</FormSection>
            <FormLine
              label="Réinscription ouverte pour les membres du CA"
              help="Ouverture des inscriptions pour les familles qui sont membres du CA"
              htmlFor="reinscription_ca_ouverte"
            >
              <ToggleInput
                id="reinscription_ca_ouverte"
                required
                checked={formData.reinscription_ca_ouverte}
                onChange={(checked) => {
                  setFormData({
                    ...formData,
                    reinscription_ca_ouverte: checked,
                  });
                }}
              />
            </FormLine>
            <FormLine
              label="Réinscription ouverte"
              help="Ouverture des inscriptions pour les familles qui sont déjà adhérentes"
              htmlFor="reinscription_ouverte"
            >
              <ToggleInput
                id="reinscription_ouverte"
                required
                checked={formData.reinscription_ouverte}
                onChange={(checked) => {
                  setFormData({
                    ...formData,
                    reinscription_ouverte: checked,
                  });
                }}
              />
            </FormLine>
            <FormLine
              label="Inscription ouverte"
              help="Ouverture des inscription pour les nouveaux adhérents."
              htmlFor="inscription_ouverte"
            >
              <ToggleInput
                id="inscription_ouverte"
                required
                checked={formData.inscription_ouverte}
                onChange={(checked) => {
                  setFormData({
                    ...formData,
                    inscription_ouverte: checked,
                  });
                }}
              />
            </FormLine>
          </div>
        </div>
      </div>
      <FormFooter>
        <CancelButton to={`/admin/`}>Retour au dashboard</CancelButton>
        <Submit type="submit" disabled={isFormPristine}>
          Mettre à jour la configuration
        </Submit>
      </FormFooter>
    </form>
  );
};

const Configuration = () => {
  const { requestState, load } = useAxios({
    url: routes.configuration(),
  });
  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <div>
        <H1>Configuration de l'application</H1>
      </div>
      <Card className="mt-4" title="Configuration">
        <Loading requestState={requestState}>
          {(rs) => {
            console.log(rs);
            return (
              <ConfigurationEditForm
                initialValue={rs.data.data}
                saisons={rs.data.saisons_disponibles}
              />
            );
          }}
        </Loading>
      </Card>
    </>
  );
};

export default Configuration;
