import React from 'react';
import classnames from 'classnames';
import { useState } from 'react';

const ToggleInput = ({ id = '', className, onChange = () => {}, checked }) => {
  const [isFocus, setFocus] = useState(false);

  return (
    <span
      onFocus={() => {
        setFocus(true);
      }}
      onBlur={() => {
        setFocus(false);
      }}
      className={classnames(
        'relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 cursor-pointer focus:outline-none my-2',
        className
      )}
      role="checkbox"
      id={id}
      tabIndex="0"
      onClick={() => {
        onChange(!checked);
      }}
      aria-checked={checked}
    >
      <span
        aria-hidden="true"
        className={classnames(
          'absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
          {
            'bg-blue-600': checked,
            'bg-gray-200': !checked,
          }
        )}
      ></span>
      <span
        aria-hidden="true"
        className={classnames(
          'absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform transition-transform ease-in-out duration-200',
          {
            'translate-x-5': checked,
            'translate-x-0': !checked,
            'shadow-outline border-blue-300': isFocus,
          }
        )}
      ></span>
    </span>
  );
};

export default ToggleInput;
