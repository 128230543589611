import React from 'react';
import classnames from 'classnames';

const FormLine = ({
  className,
  children,
  help,
  label,
  htmlFor,
  isRequired,
  ...otherProps
}) => (
  <div
    className={classnames(
      'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5 px-2 md:px-0',
      className
    )}
    {...otherProps}
  >
    <div>
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-gray-700 leading-5 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      {isRequired && (
        <p className="text-xs font-thin text-gray-600">
          Ce champ est obligatoire
        </p>
      )}
      {help && <p className="text-xs font-thin text-gray-600">{help}</p>}
    </div>
    <div className="mt-1 sm:mt-0 sm:col-span-2">{children}</div>
  </div>
);

export default FormLine;
