import React from 'react';

import Strong from 'src/components/design-system/strong';

const MainStats = ({ data }) => (
  <>
    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Finance</h3>
      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
        Informations financières de la saison
      </p>
    </div>
    <div className="px-4 py-5 sm:p-0">
      <dl>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Inscriptions réglées
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            <Strong>{data.paiementOk.count}</Strong> inscriptions pour un total
            de <Strong>{data.paiementOk.paid}</Strong> euros
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Inscriptions non réglées
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            <Strong>{data.paiementNotPaid.count}</Strong> inscriptions pour un
            total de <Strong>{data.paiementNotPaid.balance}</Strong> euros
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Inscriptions réglées en parties
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            <Strong>{data.paiementMissing.count}</Strong> inscriptions pour un
            total de <Strong>{data.paiementMissing.balance}</Strong> euros
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Inscriptions avec surplus à rembourser
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            <Strong>{data.paiementOverPaid.count}</Strong> inscriptions pour un
            total de <Strong>{data.paiementOverPaid.balance}</Strong> euros
          </dd>
        </div>
      </dl>
    </div>
  </>
);

export default MainStats;
