import React from 'react';

import useForm from 'src/hooks/useForm';
import ErrorAlert from 'src/components/design-system/alerts/error';
import CancelButton from 'src/components/design-system/buttons/cancel';
import Submit from 'src/components/design-system/buttons/submit';
import { convertDateFrToISO } from 'src/utils/date';

import * as Form from 'src/components/design-system/form';

const EnfantForm = ({
  initialValue,
  axiosConfig,
  familleId,
  labelSubmit = 'Créer',
}) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue,
    axiosConfig,
    redirectUrl: `/admin/familles/${familleId}`,
    formToPayload: (form) => ({
      ...form,
      date_naissance: convertDateFrToISO(form.date_naissance),
    }),
  });

  const updateTextField = (fieldName) => (e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  return (
    <Form.Card
      onSubmit={submitForm}
      className="mt-4"
      title="Information générales"
    >
      <div>
        {requestState.status === 'error' && (
          <ErrorAlert className="mt-4" message={requestState.error.message} />
        )}
        <div className="border-gray-200">
          <div className="mt-6 sm:mt-5">
            <Form.Line label="Prénom" htmlFor="prenom">
              <Form.Input
                id="prenom"
                value={formData.prenom}
                onChange={updateTextField('prenom')}
                requestState={requestState}
              />
            </Form.Line>

            <Form.Line label="Nom" htmlFor="nom">
              <Form.Input
                id="nom"
                value={formData.nom}
                onChange={updateTextField('nom')}
                requestState={requestState}
              />
            </Form.Line>

            <Form.Line label="Date de naissance" htmlFor="date_naissance">
              <Form.Date
                id="date_naissance"
                required
                value={formData.date_naissance}
                onChange={updateTextField('date_naissance')}
                requestState={requestState}
              />
            </Form.Line>

            <Form.Line label="Vaccination" htmlFor="vaccination">
              <Form.Select
                id="vaccination"
                value={formData.vaccination}
                onChange={updateTextField('vaccination')}
                requestState={requestState}
              >
                <option value="non">Non vérifié</option>
                <option value="carnet">Carnet présenté</option>
                <option value="certificat">Certificat présenté</option>
              </Form.Select>
            </Form.Line>
            <Form.Line label="Sexe" htmlFor="sexe">
              <Form.Select
                id="sexe"
                value={formData.sexe}
                onChange={updateTextField('sexe')}
                requestState={requestState}
              >
                <option value="garcon">Garçon</option>
                <option value="fille">Fille</option>
                <option value="">Non précisé</option>
              </Form.Select>
            </Form.Line>
          </div>
        </div>
      </div>
      <Form.Footer>
        <CancelButton to={`/admin/familles/${familleId}`}>Retour</CancelButton>
        <Submit type="submit" disabled={isFormPristine}>
          {labelSubmit}
        </Submit>
      </Form.Footer>
    </Form.Card>
  );
};

export default EnfantForm;
