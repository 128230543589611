import React from 'react';
import ActionButton from 'src/components/design-system/buttons/action';
import DateInput from 'src/components/design-system/form/date';
import SimpleInput from 'src/components/design-system/form/input';
import FormLine from 'src/components/design-system/form/line';
import FormSection from 'src/components/design-system/form/section';
import Select from 'src/components/design-system/form/select';
import P from 'src/components/design-system/p';
import ErrorAlert from 'src/components/design-system/alerts/error';

import { convertDateISOToFrFull } from 'src/utils/date';

const EnfantFormFragment = ({
  enfantPosition,
  creneaux = [],
  sessions = [],
  formData,
  setFormData,
  errors,
}) => {
  const previousEnfantData = formData.enfants[enfantPosition];
  const updateTextField = (fieldName) => (e) => {
    const updatedEnfant = {
      ...previousEnfantData,
      [fieldName]: e.target.value,
    };
    const enfants = [...formData.enfants];
    enfants[enfantPosition] = updatedEnfant;

    setFormData({
      ...formData,
      enfants,
    });
  };

  const removeEnfant = () => {
    const enfants = [...formData.enfants];
    enfants.splice(enfantPosition, 1);
    setFormData({
      ...formData,
      enfants,
    });
  };

  const nbOtherKids = formData.enfants_sauvegarde
    ? formData.enfants_sauvegarde.length
    : 0;

  return (
    <div className="mt-6 sm:mt-5">
      <FormSection>
        <div className="flex flex-row items-baseline">
          <div className="flex-grow">
            Enfant {enfantPosition + nbOtherKids + 1}
          </div>
          <ActionButton onClick={removeEnfant} color="red">
            Supprimer
          </ActionButton>
        </div>
      </FormSection>

      {errors && errors._global && (
        <ErrorAlert className="mt-4" message={errors._global} />
      )}

      <FormLine label="Prénom" htmlFor="prenom" isRequired>
        <SimpleInput
          id="prenom"
          value={previousEnfantData.prenom}
          onChange={updateTextField('prenom')}
          errors={errors}
        />
      </FormLine>

      <FormLine label="Nom" htmlFor="nom" isRequired>
        <SimpleInput
          id="nom"
          value={previousEnfantData.nom}
          onChange={updateTextField('nom')}
          errors={errors}
        />
      </FormLine>

      <FormLine label="Date de naissance" htmlFor="date_naissance" isRequired>
        <DateInput
          id="date_naissance"
          required
          value={previousEnfantData.date_naissance}
          onChange={updateTextField('date_naissance')}
          errors={errors}
        />
      </FormLine>

      <FormLine label="Sexe" htmlFor="sexe">
        <Select
          id="sexe"
          value={previousEnfantData.sexe}
          onChange={updateTextField('sexe')}
          errors={errors}
        >
          <option value="fille">Fille</option>
          <option value="garcon">Garçon</option>
          <option value="">Non précisé</option>
        </Select>
      </FormLine>

      <FormLine label="Horaire" htmlFor="creneaux_id" isRequired>
        {creneaux && creneaux.length > 0 ? (
          <Select
            id="creneaux_id"
            value={previousEnfantData.creneaux_id}
            onChange={updateTextField('creneaux_id')}
            errors={errors}
          >
            <option value="">Pas de sélection</option>
            {creneaux.map((c) => (
              <option value={c.id}>
                {c.jour_seul} {c.heure_debut} – {c.intitule} – {c.age_mois_min}-
                {c.age_mois_max} mois
              </option>
            ))}
          </Select>
        ) : (
          <P className="text-red-600">Plus de créneau disponibles.</P>
        )}
      </FormLine>

      <FormLine label="Date de début" htmlFor="sessions_id" isRequired>
        {sessions && sessions.length !== 0 ? (
          <Select
            id="sessions_id"
            value={previousEnfantData.sessions_id}
            onChange={updateTextField('sessions_id')}
            errors={errors}
          >
            <option value="">Pas de sélection</option>
            {sessions.map((s) => (
              <option value={s.id}>
                {convertDateISOToFrFull(s.date_debut_activite)}
              </option>
            ))}
          </Select>
        ) : (
          <P className="text-red-600">
            Pas de sessions disponibles pour le moment.
          </P>
        )}
      </FormLine>
    </div>
  );
};

export default EnfantFormFragment;
