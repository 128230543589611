import React from 'react';
import Strong from 'src/components/design-system/strong';

import { SecondaryTable } from 'src/components/design-system/table';

const SessionsStats = ({ data }) => {
  const totalInscrits = data.totalEnfants;
  let sum = 0;
  const sessions = data.sessions.map((s) => {
    const percent = ((100 * s.count) / totalInscrits).toFixed(2);
    sum += s.count;
    return [s.debut, s.count, `${percent}%`, sum];
  });
  return (
    <>
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-lg font-medium text-gray-900 leading-6">
          Sessions
        </h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500 leading-5">
          Informations sur les sessions
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <dt className="text-sm font-medium text-gray-500 leading-5">
              Nombre de sessions
              <div className="font-light"></div>
            </dt>
            <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
              <Strong>{data.sessions.length}</Strong>
            </dd>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <dt className="text-sm font-medium text-gray-500 leading-5">
              Répartition des inscriptions par sessions
              <div className="font-light"></div>
            </dt>
            <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
              <SecondaryTable
                headers={[
                  'Début de la session',
                  'Inscrits',
                  'Pourcentage',
                  'Total',
                ]}
                lines={sessions}
              />
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
};

export default SessionsStats;
