import React from 'react';
import { navigate } from 'gatsby';

import * as DesignSystem from 'src/components/design-system';
import Markdown from 'src/components/design-system/markdown';

import useAxios, { useAxiosNow, isLoading } from 'src/hooks/axios/useAxios';

import routes from 'src/config/routes';

const ViewNews = ({ data: news }) => {
  const { requestState, load: deleteNews } = useAxios({
    method: 'DELETE',
    url: routes.new(news.id),
  });

  return (
    <>
      <DesignSystem.Header
        title="Nouvelles"
        back={{ to: '/admin/news', label: 'Retour à la liste' }}
        secondaryAction={{ to: `/admin/news/${news.id}/edit`, label: 'Éditer' }}
        action={{
          label: 'Supprimer',
          onClick: (e) => {
            e.preventDefault();
            deleteNews().then(({ error }) => {
              if (!error) navigate('/admin/news');
            });
          },
          color: 'red',
          mustConfirm: true,
          isLoading: isLoading(requestState),
          confirmationMessage:
            'Êtes vous sur de vouloir supprimer cette nouvelle?',
        }}
      ></DesignSystem.Header>
      <DesignSystem.Card title="Nouvelle publiée" className="mt-4">
        <div className="px-2 py-1 bg-gray-50 rounded-lg mt-2">
          <DesignSystem.H3>{news.title}</DesignSystem.H3>
          <Markdown>{news.body}</Markdown>
        </div>
        <div className="text-xs mt-2">
          <DesignSystem.P>
            Auteur : {news.author.name} / {news.author.email}
          </DesignSystem.P>
          <DesignSystem.P>Créé le : {news.created_at}</DesignSystem.P>
          <DesignSystem.P>Édité le : {news.updated_at}</DesignSystem.P>
        </div>
      </DesignSystem.Card>
    </>
  );
};

const ViewNewsWithData = ({ id }) => {
  const { requestState } = useAxiosNow({
    url: routes.new(id),
  });

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rs) => <ViewNews {...rs.data} />}
    </DesignSystem.Loading>
  );
};

export default ViewNewsWithData;
