import React from 'react';
import qs from 'qs';

import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import Loading from 'src/components/design-system/loading';
import MainStats from './general/main';
import PopStats from './general/population';
import FinanceStats from './general/finance';
import IssuesStats from './general/issues';
import Header from 'src/components/design-system/header';
import CreneauxStats from './general/creneaux';
import SessionsStats from './general/sessions';
import Select from 'src/components/design-system/form/select';
import { navigate } from 'gatsby';

const Statistics = ({ location }) => {
  const saison = location.search
    ? qs.parse(location.search.slice(1)).saison
    : undefined;

  const { requestState } = useAxiosNow({
    url: routes.statistics(saison),
  });

  return (
    <Loading requestState={requestState}>
      {(rs) => {
        const data = rs.data.data;
        const saisons = rs.data.saisons_disponibles;
        return (
          <>
            <Header title={`Saison ${data.currentSaison}`}>
              <Select
                id="saison_courante"
                required
                value={data.currentSaisonId}
                onChange={(e) => {
                  const saisonId = e.target.value;
                  navigate(`${location.pathname}?saison=${saisonId}`);
                }}
              >
                {saisons &&
                  saisons.map((s) => (
                    <option value={s.id}>
                      {s.annee_debut} - {s.annee_fin}
                    </option>
                  ))}
              </Select>
            </Header>
            <div className="mt-4 overflow-hidden bg-white shadow sm:rounded-lg">
              <MainStats data={data} />
              <hr className="border-t border-gray-100" />
              <PopStats data={data} />
              <hr className="border-t border-gray-100" />
              <FinanceStats data={data} />
              <hr className="border-t border-gray-100" />
              <IssuesStats data={data} />
              <hr className="border-t border-gray-100" />
              <CreneauxStats data={data} />
              <hr className="border-t border-gray-100" />
              <SessionsStats data={data} />
            </div>
          </>
        );
      }}
    </Loading>
  );
};

export default Statistics;
