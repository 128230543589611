import { DateTime } from 'luxon';

const dateFormat = 'dd/MM/yyyy';
export const convertDateFrToISO = (date) =>
  date
    ? DateTime.fromFormat(date, dateFormat, {
        locale: 'fr',
      }).toISODate()
    : '';

export const convertDateISOToFr = (date) =>
  DateTime.fromISO(date).toFormat(dateFormat);

export const convertDateISOToFrFull = (date) =>
  DateTime.fromISO(date)
    .setLocale('fr')
    .toFormat('dd LLLL yyyy');

export const getYearFromISO = (date) => DateTime.fromISO(date).get('year');
