import React, { useEffect } from 'react';

import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import { navigate } from 'gatsby';

import * as DesignSystem from 'src/components/design-system';
import * as Form from 'src/components/design-system/form';

import Cancel from 'src/components/design-system/buttons/cancel';
import Action from 'src/components/design-system/buttons/action';

const getLines = (familles, toggleEmail, isEmailChecked) =>
  familles.map((famille) => [
    <Form.Checkbox
      className="items-center"
      onChange={toggleEmail(famille.email)}
      checked={isEmailChecked(famille.email)}
    />,
    famille.email,
    `${famille.prenom} ${famille.nom}`,
  ]);

const SelectionAdherent = ({ setFormData, formData, adherents = [] }) => {
  useEffect(() => {
    const emails = adherents.map((a) => a.email);
    console.log(emails);
    setFormData({ ...formData, emails });
  }, []);

  const isEmailChecked = (email) =>
    formData.emails ? formData.emails.indexOf(email) !== -1 : false;

  const toggleEmail = (email) => () => {
    const emails = formData.emails ? [...formData.emails] : [];
    const emailPos = emails.indexOf(email);

    if (emailPos === -1) emails.push(email);
    else {
      emails.splice(emailPos, 1);
    }
    setFormData({
      ...formData,
      emails,
    });
  };

  return (
    <DesignSystem.Card title="Sélection des adhérents" className="mt-4">
      <DesignSystem.P className="text-gray-500">
        {adherents.length} adhérents
      </DesignSystem.P>
      <DesignSystem.Table
        headers={['', 'E-Mail', 'Nom du parent 1']}
        lines={getLines(adherents, toggleEmail, isEmailChecked)}
        className="mt-4"
      />
      <Form.Footer>
        <Cancel to="/admin/mail/send/">Retour aux filtres</Cancel>
        <Action className="ml-2" to="/admin/mail/send/redaction">
          Valider la sélection
        </Action>
      </Form.Footer>
    </DesignSystem.Card>
  );
};

const getAxiosParametersForFilters = (filterType, formData) => {
  switch (filterType) {
    case 'sessions':
      return {
        method: 'post',
        url: routes.mailingFamillesSessions(),
        payload: { sessions: formData.sessions },
      };
    case 'creneaux':
      return {
        method: 'post',
        url: routes.mailingFamillesCreneau(),
        payload: { creneaux: formData.creneaux },
      };
    case 'issues':
      return {
        method: 'post',
        url: routes.mailingFamillesIssues(),
        payload: { issue: formData.issue },
      };
    case 'saison':
      return {
        method: 'post',
        url: routes.mailingFamillesSaison(),
        payload: { saison: formData.saison },
      };
    default:
      return {};
  }
};

const SelectionAdherentWithData = ({ setFormData, formData }) => {
  const filterType = formData.filterType;
  if (!filterType) navigate('/admin/mail/send/');

  const { requestState } = useAxiosNow(
    getAxiosParametersForFilters(filterType, formData)
  );

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rs) => {
        return (
          <SelectionAdherent
            adherents={rs.data.data}
            setFormData={setFormData}
            formData={formData}
          />
        );
      }}
    </DesignSystem.Loading>
  );
};

export default SelectionAdherentWithData;
