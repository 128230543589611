import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { range } from 'lodash/fp';

const Pagination = ({
  className,
  children,
  currentPage,
  lastPage,
  linkFromPage,
  ...otherProps
}) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === lastPage;

  const firstPagerPage = Math.min(Math.max(1, currentPage - 2), lastPage);
  const lastPagerPage = Math.max(Math.min(lastPage, currentPage + 3), 1);
  const pages = range(firstPagerPage, lastPagerPage);

  return (
    <div
      className={classnames(
        'border-t border-gray-200 px-4 flex items-center justify-between sm:px-0',
        className
      )}
      {...otherProps}
    >
      <div className="flex flex-1 w-0">
        {isFirstPage ? (
          <div
            className={classnames(
              '-mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-300 transition ease-in-out duration-150'
            )}
          >
            <svg
              className="w-5 h-5 mr-3 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
            Page précédente
          </div>
        ) : (
          <Link
            to={linkFromPage(currentPage - 1)}
            className={classnames(
              '-mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150'
            )}
          >
            <svg
              className="w-5 h-5 mr-3 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
            Page précédente
          </Link>
        )}
      </div>

      <div className="hidden md:flex">
        {pages.map(p => (
          <Link
            key={p}
            to={linkFromPage(p)}
            className={classnames(
              'inline-flex items-center px-4 pt-4 -mt-px text-sm font-medium border-t-2 border-transparent leading-5 focus:outline-none transition ease-in-out duration-150',
              {
                'text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-400 ':
                  p !== currentPage,
                'text-indigo-600 focus:text-indigo-800 focus:border-indigo-700':
                  p === currentPage,
              }
            )}
          >
            {p}
          </Link>
        ))}
        {currentPage < lastPage - 3 && (
          <span className="-mt-px border-t-2 border-transparent pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium text-gray-500">
            ...
          </span>
        )}
        <Link
          to={linkFromPage(lastPage)}
          className={classnames(
            'inline-flex items-center px-4 pt-4 -mt-px text-sm font-medium border-t-2 border-transparent leading-5 focus:outline-none transition ease-in-out duration-150',
            {
              'text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-400 ':
                currentPage !== lastPage,
              'text-indigo-600 focus:text-indigo-800 focus:border-indigo-700':
                currentPage === lastPage,
            }
          )}
        >
          {lastPage}
        </Link>
      </div>
      <div className="flex justify-end flex-1 w-0">
        {isLastPage ? (
          <div className="inline-flex items-center pt-4 pr-1 -mt-px text-sm font-medium text-gray-300 border-t-2 border-transparent leading-5 transition ease-in-out duration-150">
            Page suivante
            <svg
              className="w-5 h-5 ml-3 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        ) : (
          <Link
            to={linkFromPage(currentPage + 1)}
            className="inline-flex items-center pt-4 pl-1 -mt-px text-sm font-medium text-gray-500 border-t-2 border-transparent leading-5 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150"
          >
            Page suivante
            <svg
              className="w-5 h-5 ml-3 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Pagination;
