import React from 'react';
import * as Form from 'src/components/design-system/form';
import { updateTextField } from 'src/hooks/useForm';

const IssueFilterLine = ({ issues, setFormData, formData }) => {
  const onChange = updateTextField(setFormData, formData)('issue');
  return (
    <Form.Line label="Choix de la problématique">
      <Form.Select value={formData.issue} onChange={onChange}>
        <option value="">Pas de sélection</option>
        {issues.map((i) => (
          <option value={i}>{i}</option>
        ))}
      </Form.Select>
    </Form.Line>
  );
};

export default IssueFilterLine;
