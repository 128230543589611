import React from 'react';
import * as DesignSystem from 'src/components/design-system';
import * as Form from 'src/components/design-system/form/';
import Action from 'src/components/design-system/buttons/action';
import Cancel from 'src/components/design-system/buttons/cancel';
import { updateTextField } from 'src/hooks/useForm';

const WriteMessage = ({ setFormData, formData }) => {
  const handleInputUpdate = updateTextField(setFormData, formData);
  return (
    <DesignSystem.Card
      title="Rédaction du message"
      className="mt-4"
      path="/message"
    >
      <div className="mt-4">
        <label
          for="subject"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Sujet
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            id="subject"
            value={formData.subject}
            onChange={handleInputUpdate('subject')}
            className="form-input block w-full sm:text-sm sm:leading-5"
          />
        </div>
      </div>
      <div className="mt-2">
        <label
          for="message"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Message
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <textarea
            id="message"
            rows="10"
            value={formData.message}
            onChange={handleInputUpdate('message')}
            className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          ></textarea>
        </div>
        <p className="mt-2 text-sm text-gray-500">
          Le message peut être écrit en{' '}
          <DesignSystem.Link href="https://fr.wikipedia.org/wiki/Markdown">
            Markdown
          </DesignSystem.Link>
          .
          <br />
          Le double retour à la ligne créé un nouveau paragraphe. Si vous
          entourez un groupe de mot par des **, ils seront mis{' '}
          <strong>en gras</strong>.
          <br />
          D'autres options sont disponibles mais elles ne sont pas nécessaires
          pour rédiger votre message.
        </p>
      </div>

      <Form.Footer>
        <Cancel to="/admin/mail/send/adherents">
          Retour à la sélection des adhérents
        </Cancel>
        <Action
          className="ml-2"
          to="/admin/mail/send/recapitulatif"
          disabled={!formData.message || !formData.subject}
        >
          Visualiser le message
        </Action>
      </Form.Footer>
    </DesignSystem.Card>
  );
};

export default WriteMessage;
