import { navigate } from 'gatsby';
import qs from 'qs';
import React, { useEffect } from 'react';
import classnames from 'classnames';

import Header from 'src/components/design-system/header';
import Link from 'src/components/design-system/link';
import Loading from 'src/components/design-system/loading';
import Pagination from 'src/components/design-system/search/pagination';
import Table from 'src/components/design-system/table';
import Select from 'src/components/design-system/form/select';

import routes from 'src/config/routes';

import useAxios from 'src/hooks/axios/useAxios';
import { convertDateISOToFr } from 'src/utils/date';
import { formatFamilyName } from 'src/utils/famille';

import SearchForm from './searchForm';
import SelectFilterSet from './selectFilterSet';

const tabRouteMap = {
  current: routes.famillesSaisonCourante,
  slots: routes.famillesCreneau,
  sessions: routes.famillesSession,
  membresCA: routes.famillesMembresCA,
  all: routes.familles,
};

const ListFamilles = ({ location } = {}) => {
  const searchParameters = location.search
    ? {
        page: '1',
        currentTab: 'current',
        ...qs.parse(location.search.slice(1)),
      }
    : { page: '1', currentTab: 'current' };

  const { requestState, load } = useAxios({
    url: tabRouteMap[searchParameters.currentTab](searchParameters),
  });

  useEffect(() => {
    load();
  }, [
    searchParameters.page,
    searchParameters.q,
    searchParameters.currentTab,
    searchParameters.creneau,
    searchParameters.session,
  ]);

  const getURLWithPage = (p) => {
    const query = {
      ...searchParameters,
      page: p,
    };
    return `/admin/familles?${qs.stringify(query)}`;
  };

  const onSearchSubmit = (e, search) => {
    e.preventDefault();
    const query = {
      ...searchParameters,
      page: '1',
      q: search,
    };
    navigate(`/admin/familles?${qs.stringify(query)}`);
  };

  const setTab = (tab) => {
    navigate(`/admin/familles?currentTab=${tab}`);
  };

  const setSession = (e) => {
    const query = {
      ...searchParameters,
      session: e.target.value,
    };
    navigate(`/admin/familles?${qs.stringify(query)}`);
  };

  const setCreneau = (e) => {
    const query = {
      ...searchParameters,
      creneau: e.target.value,
    };
    navigate(`/admin/familles?${qs.stringify(query)}`);
  };

  return (
    <div>
      <Header
        title="Adhérents"
        action={{ label: 'Ajouter une famille', to: '/admin/familles/new' }}
        secondaryAction={{
          label: 'Imprimer la feuille de présence',
          onClick: () => {
            window.open('/presence');
          },
          target: '_blank',
          color: 'light',
        }}
      >
        {' '}
        <SelectFilterSet
          options={[
            { value: 'current', label: 'Année en cours' },
            { value: 'slots', label: 'Par créneaux' },
            { value: 'sessions', label: 'Par sessions' },
            { value: 'membresCA', label: "Conseil d'administration" },
            { value: 'all', label: 'Tous les adhérents' },
          ]}
          value={searchParameters.currentTab}
          onChange={setTab}
        />
        {(searchParameters.currentTab === 'all' ||
          searchParameters.currentTab === 'current') && (
          <SearchForm
            className="w-full mt-4"
            defaultValue={searchParameters.q}
            onSubmit={onSearchSubmit}
          />
        )}
      </Header>

      <Loading requestState={requestState}>
        {(requestState) => {
          const {
            current_page: currentPage,
            last_page: lastPage,
            total: rawTotal,
            creneaux,
            sessions,
            currentCreneauId,
            currentSessionId,
          } = requestState.data.meta || {};

          const total = rawTotal.length ? rawTotal[0] : rawTotal;
          const lines = requestState.data.data.map((famille) => {
            const line = [
              <Link to={`/admin/familles/${famille.id}`}>
                {formatFamilyName(famille)}
              </Link>,
              convertDateISOToFr(famille.created_at),
              <Link
                key=""
                className="block w-full text-right"
                to={`/admin/familles/${famille.id}/edit`}
              >
                Editer
              </Link>,
            ];
            line.key = famille.id;
            return line;
          });

          return (
            <div className="pt-4">
              {creneaux && currentCreneauId && (
                <Select onChange={setCreneau} value={currentCreneauId}>
                  {creneaux.map((c) => (
                    <option
                      key={c.id}
                      value={c.id}
                      className={classnames('font-mono', {
                        'text-red-700': c.affichage === 0,
                        'text-green-600': c.affichage === 1,
                      })}
                    >
                      {c.ref} - {c.jour_seul} {c.heure_debut}
                    </option>
                  ))}
                </Select>
              )}
              {sessions && currentSessionId && (
                <Select onChange={setSession} value={currentSessionId}>
                  {sessions.map((s) => (
                    <option
                      key={s.id}
                      value={s.id}
                      className={classnames('font-mono', {
                        'text-red-700': s.affichage === 0,
                        'text-green-600': s.affichage === 1,
                      })}
                    >
                      {s.date_debut_activite}
                    </option>
                  ))}
                </Select>
              )}
              <div className="pt-2 pl-2 text-sm text-gray-400">
                {total} adhérents trouvés
              </div>
              <Table
                className="pt-2"
                compact
                headers={['Famille', 'Date de création', '']}
                lines={lines}
              />{' '}
              {lastPage && (
                <Pagination
                  className="mt-4"
                  currentPage={currentPage}
                  lastPage={lastPage}
                  linkFromPage={getURLWithPage}
                />
              )}
            </div>
          );
        }}
      </Loading>
    </div>
  );
};
export default ListFamilles;
