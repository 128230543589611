import React from 'react';
import Strong from 'src/components/design-system/strong';

import { SecondaryTable } from 'src/components/design-system/table';

const CreneauxStats = ({ data }) => (
  <>
    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 className="text-lg font-medium text-gray-900 leading-6">Créneaux</h3>
      <p className="max-w-2xl mt-1 text-sm text-gray-500 leading-5">
        Informations sur les créneaux de la saison
      </p>
    </div>
    <div className="px-4 py-5 sm:p-0">
      <dl>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm font-medium text-gray-500 leading-5">
            Nombre de créneaux
            <div className="font-light"></div>
          </dt>
          <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
            <Strong>{data.creneaux.length}</Strong>
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm font-medium text-gray-500 leading-5">
            Répartition des inscriptions
            <div className="font-light"></div>
          </dt>
          <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
            <SecondaryTable
              headers={['Intitulé', 'Horaire', 'Inscrits', 'Total']}
              lines={data.creneaux.map((c) => [
                c.label,
                c.debut,
                c.count,
                c.total,
              ])}
            />
          </dd>
        </div>
      </dl>
    </div>
  </>
);

export default CreneauxStats;
