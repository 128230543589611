import React from 'react';
import * as Form from 'src/components/design-system/form';
import { updateTextField } from 'src/hooks/useForm';

const SaisonFilterLine = ({ saisons, setFormData, formData }) => {
  const onChange = updateTextField(setFormData, formData)('saison');
  return (
    <Form.Line label="Sélection de la saison">
      <Form.Select value={formData.saison} onChange={onChange}>
        <option value="">Pas de saison sélectionnée</option>
        {saisons.map((s) => (
          <option value={s.id}>
            Saison {s.annee_debut}-{s.annee_fin}
          </option>
        ))}
      </Form.Select>
    </Form.Line>
  );
};

export default SaisonFilterLine;
