import React from 'react';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import * as DesignSystem from 'src/components/design-system';
import map from 'lodash/map';

const getLines = (stats) => [
  ['Enfants inscrits', ...map(stats, 'nbEnfants')],
  ['Familles inscrites', ...map(stats, 'nbAdherents')],
  ['Cotisations payées', ...map(stats, 'prixPaye')],
];

const LastWeekStats = ({ stats }) => (
  <>
    <DesignSystem.H2 className="mt-4">
      Inscriptions de la semaine passée
    </DesignSystem.H2>
    <DesignSystem.Table
      headers={['', ...Object.keys(stats)]}
      lines={getLines(stats)}
      className="w-full mt-2"
    />
  </>
);

const InscriptionsWithData = () => {
  const { requestState } = useAxiosNow({
    url: routes.inscriptionLastWeek(),
    retry: 3,
  });
  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rs) => <LastWeekStats stats={rs.data.data} />}
    </DesignSystem.Loading>
  );
};

export default InscriptionsWithData;
