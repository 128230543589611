export const findError = (requestState, id) => {
  if (
    requestState.status === 'error' &&
    requestState.error &&
    requestState.error.errors
  ) {
    const errorMessage = requestState.error.errors[id];
    return errorMessage ? errorMessage : null;
  }
  return null;
};
