import React from 'react';
import * as DesignSystem from 'src/components/design-system';

const Contact = ({ famille }) => {
  return (
    <div className="mt-4 overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <dt className="text-sm font-medium text-gray-500 leading-5">
              Parent 1
            </dt>
            <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
              <span className="capitalize">{famille.parent1_prenom}</span>{' '}
              {famille.parent1_nom.toUpperCase()}
              <DesignSystem.P>{famille.tel_principal}</DesignSystem.P>
            </dd>
          </div>

          {famille.parent2_nom && (
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Parent 2
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                <span className="capitalize">{famille.parent2_prenom}</span>{' '}
                {famille.parent2_nom.toUpperCase()}
                {famille.tel_secondaire && (
                  <DesignSystem.P>{famille.tel_secondaire}</DesignSystem.P>
                )}
              </dd>
            </div>
          )}
          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm font-medium text-gray-500 leading-5">
              E-mails
            </dt>
            <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
              <ul className="list-disc list-inside">
                <li>{famille.mail_principal}</li>
                {famille.mail_secondaire && <li>{famille.mail_secondaire}</li>}
              </ul>
            </dd>
          </div>
          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm font-medium text-gray-500 leading-5">
              Adresse postale
            </dt>
            <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
              <DesignSystem.P>{famille.adresse}</DesignSystem.P>
              <DesignSystem.P>
                {famille.code_postal} {famille.ville}
              </DesignSystem.P>
            </dd>
          </div>
          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm font-medium text-gray-500 leading-5">
              Vie associative
            </dt>
            <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
              <DesignSystem.P>
                Fait partie du CA :{' '}
                <strong>{famille.is_membreCA ? 'oui' : 'non'}</strong>
              </DesignSystem.P>
            </dd>
          </div>
          {famille.commentaire && (
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Commentaires
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                <DesignSystem.P>{famille.commentaire}</DesignSystem.P>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
};

export default Contact;
