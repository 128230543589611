import { Router } from '@reach/router';
import React from 'react';

import Dashboard from 'src/components/admin/dashboard';
import AdminGuard from 'src/components/admin/guard';

import AddUser from 'src/components/admin/team/add';
import EditUser from 'src/components/admin/team/edit';
import Team from 'src/components/admin/team/list';

import Issues from 'src/components/admin/issues';

import Layout from 'src/components/layouts/admin';
import SEO from 'src/components/seo';
import Configuration from 'src/components/admin/configuration';
import Statistics from 'src/components/admin/statistics';

import Saisons from 'src/components/admin/saison';
import Familles from 'src/components/admin/famille';
import Mailing from 'src/components/admin/mailing';
import News from 'src/components/admin/news';

const IndexPage = ({ location }) => (
  <AdminGuard>
    {({ userProfile }) => (
      <Layout userProfile={userProfile}>
        <SEO title="Administration" />
        <Router basepath="/admin">
          <Dashboard path="/" userProfile={userProfile} />
          <Configuration path="/configuration" userProfile={userProfile} />

          <Saisons path="saisons/*" />
          <Familles path="familles/*" />
          <Mailing path="mail/*" />
          <News path="news/*" />

          <Team path="team" userProfile={userProfile} />
          <EditUser path="team/:id" userProfile={userProfile} />
          <AddUser path="team/new" userProfile={userProfile} />

          <Issues
            path="dossiers/*"
            userProfile={userProfile}
            location={location}
          />
          <Statistics path="stats" userProfile={userProfile} />
        </Router>
      </Layout>
    )}
  </AdminGuard>
);

export default IndexPage;
