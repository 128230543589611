import React from 'react';

import Header from 'src/components/design-system/header';
import Loading from 'src/components/design-system/loading';
import ErrorAlert from 'src/components/design-system/alerts/error';

import useAxios, { useAxiosNow, isLoading } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';

import CreneauForm from './form';
import { navigate } from 'gatsby';

const EditCreneau = ({ creneauId, saisonId }) => {
  const { requestState } = useAxiosNow({
    url: routes.creneau(creneauId),
  });

  const { requestState: rsDelete, load: deleteCreneau } = useAxios({
    method: 'delete',
    url: routes.creneau(creneauId),
  });

  return (
    <>
      <Header
        title="Éditer le créneau"
        action={{
          label: 'Supprimer',
          onClick: (e) => {
            e.preventDefault();
            deleteCreneau().then(({ error }) => {
              if (!error) navigate(`/admin/saisons/${saisonId}`);
            });
          },
          color: 'red',
          mustConfirm: true,
          isLoading: isLoading(rsDelete),
          confirmationMessage: 'Êtes vous sur de vouloir supprimer ce créneau?',
        }}
      >
        {rsDelete.status === 'error' && (
          <ErrorAlert
            className="mt-4 w-full"
            message={rsDelete.error.message}
          />
        )}
      </Header>
      <Loading requestState={requestState}>
        {(rs) => {
          const data = rs.data.data;
          return (
            <CreneauForm
              initialValue={{ ...data, jour: data.jour_seul }}
              axiosConfig={{
                method: 'PUT',
                url: routes.creneau(creneauId),
              }}
              saisonId={saisonId}
              labelSubmit="Valider"
            />
          );
        }}
      </Loading>
    </>
  );
};

export default EditCreneau;
