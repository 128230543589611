import { useState } from 'react';
import useAxios, { isLoading } from './axios/useAxios';
import isEqual from 'lodash/fp/isEqual';
import { navigate } from 'gatsby';
import isFunction from 'lodash/isFunction';

const useForm = ({
  initialValue,
  axiosConfig,
  formToPayload = (i) => i,
  redirectUrl,
}) => {
  const [formData, setFormData] = useState(initialValue);
  const { load, requestState } = useAxios(axiosConfig);
  const isFormPristine = isEqual(initialValue, formData);
  const submitForm = (e) => {
    e.preventDefault();
    const payload = formToPayload(formData);
    load({ payload }).then(({ data, error }) => {
      if (!error) {
        if (isFunction(redirectUrl)) {
          navigate(redirectUrl(data));
        } else {
          navigate(redirectUrl);
        }
      } else {
        document.querySelector('form').scrollIntoView({ behavior: 'smooth' });
      }
    });
  };

  const errors =
    requestState.status === 'error' &&
    requestState.error &&
    requestState.error.errors
      ? requestState.error.errors
      : {};

  const isRSLoading = isLoading(requestState);

  return {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
    errors,
    isLoading: isRSLoading,
  };
};

export const updateTextField = (setFormData, formData) => (fieldName) => (
  e
) => {
  setFormData({
    ...formData,
    [fieldName]: e.target.value,
  });
};

export default useForm;
