import React from 'react';

import routes from 'src/config/routes';

import * as DesignSystem from 'src/components/design-system';

import FamilleForm from './form';

const AddFamille = () => {
  return (
    <>
      <DesignSystem.Header title="Créer une nouvelle famille" />
      <FamilleForm
        initialValue={{
          parent1_nom: '',
          parent1_prenom: '',
          parent2_nom: '',
          parent2_prenom: '',
          mail_principal: '',
          mail_secondaire: '',
          tel_principal: '',
          tel_secondaire: '',
          adresse: '',
          code_postal: '',
          ville: '',
          commentaire: '',
        }}
        axiosConfig={{
          method: 'POST',
          url: routes.familles(),
        }}
      />
    </>
  );
};

export default AddFamille;
