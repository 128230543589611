import React, { useEffect } from 'react';

import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';

import * as DesignSystem from 'src/components/design-system';
import * as Form from 'src/components/design-system/form';

import Cancel from 'src/components/design-system/buttons/cancel';
import Action from 'src/components/design-system/buttons/action';

import { isLoading } from 'src/hooks/axios/useAxios';

const Preview = ({ html, formData, sendEmail, rsEmail }) => {
  return (
    <DesignSystem.Card title="Récapitulatif" className="mt-4">
      <form>
        <div className="mt-4">
          <div className="block text-sm font-medium text-gray-700 leading-5">
            Destinataires ({formData.emails.length})
          </div>
          <ul className="flex flex-wrap">
            {formData.emails.map((e) => (
              <li className="mt-1 ml-1">
                <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-blue-100 text-blue-800">
                  {e}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-2">
          <div className="block text-sm font-medium text-gray-700 leading-5">
            Sujet
          </div>
          <div className="relative mt-1 rounded-md shadow-sm border border-gray-100 px-2 py-1">
            {formData.subject}
          </div>
        </div>
        <div className="mt-2">
          <div className="block text-sm font-medium text-gray-700 leading-5">
            Prévisualisation du message
          </div>
          <div className="relative mt-1 rounded-md shadow-sm">
            <iframe srcDoc={html} className="w-full h-72" />
          </div>
        </div>
        <Form.Footer>
          <Cancel to="/admin/mail/send/redaction">Éditer le message</Cancel>
          <Action
            className="ml-2"
            mustConfirm
            confirmationMessage={`Êtes-vous sûr(e) de vouloir envoyer ce message à ${formData.emails.length} adhérent(s) ?`}
            onClick={sendEmail}
            isLoading={isLoading(rsEmail)}
          >
            Envoyer
          </Action>
        </Form.Footer>
      </form>
    </DesignSystem.Card>
  );
};

const PreviewWithData = ({ formData, sendEmail, rsEmail }) => {
  const { requestState } = useAxiosNow({
    payload: { message: formData.message, subject: formData.subject },
    method: 'post',
    url: routes.mailingPreview(),
  });

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rsPreview) => (
        <Preview
          html={rsPreview.data}
          formData={formData}
          sendEmail={sendEmail}
          rsEmail={rsEmail}
        />
      )}
    </DesignSystem.Loading>
  );
};

export default PreviewWithData;
