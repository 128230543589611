import classnames from 'classnames';
import React from 'react';
import { useEffect } from 'react';
import Header from 'src/components/design-system/header';
import SecondaryHeader from 'src/components/design-system/header/secondary';
import StyledLink from 'src/components/design-system/link';
import Loading from 'src/components/design-system/loading';
import Table from 'src/components/design-system/table';
import routes from 'src/config/routes';
import useAxios from 'src/hooks/axios/useAxios';

import { convertDateISOToFr } from 'src/utils/date';
import { diffMinutesFromStrings } from 'src/utils/time';

const formatCreneaux = (creneaux, saisonId, isCurrentSaison) =>
  creneaux.map((c) => {
    const line = [
      c.ref,
      c.intitule,
      <span
        className={classnames({
          'text-green-500': c.affichage === 1,
          'text-red-900': c.affichage === 0,
        })}
      >
        {c.affichage === 1 ? 'visible' : 'caché'}
      </span>,
      c.jour_seul,
      c.heure_debut,
      `${c.age_mois_min} - ${c.age_mois_max}`,
      diffMinutesFromStrings(c.heure_debut, c.heure_fin),
      c.nbplace_total,
      <div className="flex flex-col">
        <StyledLink to={`/admin/saisons/${saisonId}/creneaux/${c.id}`}>
          Editer
        </StyledLink>
        {isCurrentSaison ? (
          <StyledLink
            className=""
            to={`/admin/familles?currentTab=slots&creneau=${c.id}`}
          >
            Voir les Adhérents
          </StyledLink>
        ) : (
          ''
        )}
      </div>,
    ];

    if (isCurrentSaison) {
      line.push();
    }

    return line;
  });

const formatSessions = (sessions, saisonId) =>
  sessions.map((s) => [
    <span className="font-mono">
      {convertDateISOToFr(s.date_debut_activite)}
    </span>,
    <span
      className={classnames({
        'text-green-500': s.affichage === 1,
        'text-red-900': s.affichage === 0,
      })}
    >
      {s.affichage === 1 ? 'visible' : 'caché'}
    </span>,
    s.adhesion,
    s.enfant1,
    s.enfant2,
    s.enfant3,
    <StyledLink to={`/admin/saisons/${saisonId}/sessions/${s.id}`}>
      Editer
    </StyledLink>,
  ]);

const ViewSaison = ({ id }) => {
  const { requestState: userRequestState, load } = useAxios({
    url: routes.saison(id),
  });

  useEffect(() => {
    load();
  }, []);

  return (
    <Loading requestState={userRequestState}>
      {(rs) => {
        const {
          data: {
            data: {
              annee_debut,
              annee_fin,
              sessions,
              creneaux,
              current: isCurrentSaison,
            },
          },
        } = rs;
        return (
          <>
            <Header
              title={`Saison ${annee_debut} – ${annee_fin}`}
              back={{
                to: '/admin/saisons',
                label: 'Retour à la liste des saisons',
              }}
            />
            <SecondaryHeader
              title="Créneaux"
              className="mt-5 md:mt-10"
              action={{
                label: 'Ajouter un créneau',
                to: `/admin/saisons/${id}/creneaux/new`,
              }}
            />
            {creneaux && (
              <Table
                className="mt-2"
                headers={[
                  'Ref',
                  'Libellé',
                  'Visibilité',
                  'Jour',
                  'Début',
                  'Age (mois)',
                  'Durée (minutes)',
                  'Places',
                  '',
                ]}
                lines={formatCreneaux(creneaux, id, isCurrentSaison)}
              ></Table>
            )}

            <SecondaryHeader
              title="Sessions"
              action={{
                label: 'Ajouter une session',
                to: `/admin/saisons/${id}/sessions/new`,
              }}
              className="mt-5 md:mt-10"
            />

            {sessions && (
              <Table
                className="mt-2"
                headers={[
                  "Début de l'activité",
                  'Visibilité',
                  'Adhésion',
                  'Premier enfant',
                  'Deuxième enfant',
                  'Troisième enfant',
                  '',
                ]}
                lines={formatSessions(sessions, id)}
              ></Table>
            )}
          </>
        );
      }}
    </Loading>
  );
};

export default ViewSaison;
