import React from 'react';

import routes from 'src/config/routes';
import * as DesignSystem from 'src/components/design-system';

import Form from 'src/components/admin/news/form';

const AddNews = () => {
  return (
    <DesignSystem.Card title="Ajouter une nouvelle">
      <Form
        axiosConfig={{
          method: 'POST',
          url: routes.news(),
        }}
      />
    </DesignSystem.Card>
  );
};

export default AddNews;
