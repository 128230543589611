import React from 'react';

import useForm from 'src/hooks/useForm';

import Submit from 'src/components/design-system/buttons/submit';
import SimpleInput from 'src/components/design-system/form/input';
import FormLine from 'src/components/design-system/form/line';
import FormFooter from 'src/components/design-system/form/footer';
import CancelButton from 'src/components/design-system/buttons/cancel';

const EditForm = ({ userData = { name: '', email: '' }, axiosConfig }) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue: userData,
    axiosConfig,
    redirectUrl: '/admin/team',
  });

  const updateTextField = (fieldName) => (e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  return (
    <form onSubmit={submitForm}>
      <div>
        <div className="border-gray-200">
          <div className="mt-6 sm:mt-5">
            <FormLine label="Nom" htmlFor="name">
              <SimpleInput
                id="name"
                value={formData.name}
                onChange={updateTextField('name')}
                requestState={requestState}
              />
            </FormLine>
            <FormLine label="Adresse e-mail" htmlFor="email">
              <SimpleInput
                id="email"
                value={formData.email}
                onChange={updateTextField('email')}
                requestState={requestState}
              />
            </FormLine>
          </div>
        </div>
      </div>
      <FormFooter>
        <span className="inline-flex rounded-md shadow-sm">
          <CancelButton to="/admin/team">Retour à la liste</CancelButton>
        </span>
        <Submit type="submit" disabled={isFormPristine}>
          Enregistrer
        </Submit>
      </FormFooter>
    </form>
  );
};

export default EditForm;
