import React from 'react';
import { Router } from '@reach/router';

import * as DesignSystem from 'src/components/design-system';
import useForm from 'src/hooks/useForm';
import routes from 'src/config/routes';

import Filters from './filters';
import SelectionAdherent from './adherents';
import WriteMessage from './write';
import Preview from './preview';

const Send = () => {
  const {
    formData,
    setFormData,
    // isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue: {},
    axiosConfig: {
      method: 'post',
      url: routes.mailingSend(),
    },
    formToPayload: (i) => i,
    redirectUrl: '/admin/mail/',
  });

  return (
    <div>
      <DesignSystem.Header
        title="Nouveau message aux adhérents"
        back={{ label: 'Retour à la liste', to: '/admin/mail' }}
      />

      <Router>
        <Filters path="/" setFormData={setFormData} formData={formData} />
        <SelectionAdherent
          path="/adherents"
          setFormData={setFormData}
          formData={formData}
        />
        <WriteMessage
          path="/redaction"
          setFormData={setFormData}
          formData={formData}
        />
        <Preview
          path="/recapitulatif"
          setFormData={setFormData}
          formData={formData}
          sendEmail={submitForm}
          rsEmail={requestState}
        />
      </Router>
    </div>
  );
};

export default Send;
