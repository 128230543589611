import React from 'react';

import * as DesignSystem from 'src/components/design-system';
import * as Form from 'src/components/design-system/form/';
import { isLoading } from 'src/hooks/axios/useAxios';

import Submit from 'src/components/design-system/buttons/submit';

import useForm from 'src/hooks/useForm';
import ErrorAlert from 'src/components/design-system/alerts/error';

import Cancel from 'src/components/design-system/buttons/cancel';

import { updateTextField } from 'src/hooks/useForm';

const NewsForm = ({
  initialValue = { title: '', body: '' },
  axiosConfig,
  labelSubmit = 'Créer',
}) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue,
    axiosConfig,
    redirectUrl: '/admin/news',
  });

  const handleInputUpdate = updateTextField(setFormData, formData);

  return (
    <form onSubmit={submitForm} className="mt-4">
      {requestState.status === 'error' && (
        <ErrorAlert className="mt-4" message={requestState.error.message} />
      )}
      <div className="mt-4">
        <label
          for="title"
          className="block text-sm font-medium text-gray-700 leading-5"
        >
          Titre
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            id="title"
            value={formData.title}
            onChange={handleInputUpdate('title')}
            className="block w-full form-input sm:text-sm sm:leading-5"
          />
        </div>
      </div>
      <div className="mt-2">
        <label
          for="body"
          className="block text-sm font-medium text-gray-700 leading-5"
        >
          Contenu
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <textarea
            id="body"
            rows="10"
            value={formData.body}
            onChange={handleInputUpdate('body')}
            className="block w-full form-textarea transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          ></textarea>
        </div>
        <p className="mt-2 text-sm text-gray-500">
          Le contenu peut être écrit en{' '}
          <DesignSystem.Link href="https://fr.wikipedia.org/wiki/Markdown">
            Markdown
          </DesignSystem.Link>
          .
          <br />
          Le double retour à la ligne créé un nouveau paragraphe. Si vous
          entourez un groupe de mot par des **, ils seront mis{' '}
          <strong>en gras</strong>.
        </p>
      </div>

      <Form.Footer>
        <Cancel to="/admin/news">Retour à la liste des actualités</Cancel>
        <Submit
          type="submit"
          disabled={isFormPristine}
          isLoading={isLoading(requestState)}
        >
          {labelSubmit}
        </Submit>
      </Form.Footer>
    </form>
  );
};

export default NewsForm;
