import React, { useState } from 'react';

import * as DesignSystem from 'src/components/design-system';
import * as Form from 'src/components/design-system/form';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';

const getLines = (creneaux, displayNextSeason) =>
  creneaux.map((c) => [
    c.ref,
    <span>{c.inscriptions.length}</span>,
    <span>{c.nbplace_total}</span>,
    <span
      className={c.inscriptions.length > c.nbplace_total ? 'text-red-500' : ''}
    >
      {((100 * c.inscriptions.length) / c.nbplace_total).toFixed(1)} %
    </span>,
    <div className="flex flex-col">
      {!displayNextSeason && (
        <DesignSystem.Link
          to={`/admin/familles?currentTab=slots&creneau=${c.id}`}
        >
          Voir les adhérents
        </DesignSystem.Link>
      )}
      <DesignSystem.Link to={`/admin/saisons/${c.saison_id}/creneaux/${c.id}`}>
        Editer le créneau
      </DesignSystem.Link>
    </div>,
  ]);

const AvailableCreneaux = ({ creneaux, displayNextSeason }) => (
  <DesignSystem.Table
    headers={['Réference', 'Inscriptions', 'Places', 'Occupation', 'Actions']}
    lines={getLines(creneaux, displayNextSeason)}
    className="w-full mt-2"
  />
);

const AvailableCreneauxWithData = () => {
  const [displayNextSeason, setDisplayNextSeason] = useState(false);

  const { requestState } = useAxiosNow({
    url: displayNextSeason ? routes.creneauxNext() : routes.creneaux(),
    retry: 3,
  });

  return (
    <>
      <div className="flex flex-row w-full items-baseline">
        <DesignSystem.H2 className="mt-4 flex-grow">
          Occupation des créneaux
        </DesignSystem.H2>
        <label className="flex items-center flex-row font-thin">
          Voir la prochaine saison
          <Form.Toggle
            className="m-auto ml-2 -my-2"
            checked={displayNextSeason}
            onChange={() => setDisplayNextSeason(!displayNextSeason)}
          />
        </label>
      </div>

      <DesignSystem.Loading requestState={requestState}>
        {(rs) => {
          return (
            <AvailableCreneaux
              creneaux={rs.data.data}
              displayNextSeason={displayNextSeason}
            />
          );
        }}
      </DesignSystem.Loading>
    </>
  );
};

export default AvailableCreneauxWithData;
