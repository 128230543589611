import React from 'react';

import routes from 'src/config/routes';
import H1 from 'src/components/design-system/h1';
import FormSaison from './form';

const AddSaison = () => {
  return (
    <div>
      <H1 className="text-lg font-medium text-gray-900 leading-6">
        Ajouter une nouvelle saison
      </H1>
      <FormSaison
        axiosConfig={{
          url: routes.saisons(),
          method: 'post',
        }}
      />
    </div>
  );
};

export default AddSaison;
