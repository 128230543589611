import React from 'react';

import EnfantFormFragment from './enfantInscrit';

const regex = /enfants_sauvegarde\.(\d+)(?:\.(\w+))?/;
const getEnfantsErrors = (errors) => {
  if (!errors || errors.length === 0) return [];
  const errorsByEnfants = [];
  Object.keys(errors).forEach((k) => {
    const match = regex.exec(k);
    if (match !== null) {
      const pos = parseFloat(match[1]);
      const key = match[2] || '_global';
      if (errorsByEnfants[pos]) errorsByEnfants[pos][key] = errors[k];
      else errorsByEnfants[pos] = { [key]: errors[k] };
    }
  });
  return errorsByEnfants;
};

const EnfantsInscritsForm = ({
  requestState,
  formData,
  setFormData,
  sessions,
  creneaux,
}) => {
  const errorsByEnfants = getEnfantsErrors(
    requestState.error && requestState.error.errors
  );

  return (
    <>
      {!formData.enfants_sauvegarde ||
        (formData.enfants_sauvegarde.length > 0 && (
          <>
            {formData.enfants_sauvegarde.map((_, i) => {
              const errEnfant = errorsByEnfants[i];
              return (
                <EnfantFormFragment
                  key={i}
                  enfantPosition={i}
                  formData={formData}
                  setFormData={setFormData}
                  creneaux={creneaux}
                  sessions={sessions}
                  requestState={requestState}
                  errors={errEnfant}
                />
              );
            })}
          </>
        ))}
    </>
  );
};

export default EnfantsInscritsForm;
