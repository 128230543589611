import React, { useEffect } from 'react';
import { useAuth } from '../authentication';
import { navigate } from 'gatsby';
import { Grid as GridLoader } from 'svg-loaders-react';
import P from 'src/components/design-system/p';

import Splash from 'src/components/design-system/splash';

const Checking = () => (
  <Splash>
    <P className="m-auto text-2xl">
      Vérification de la connexion{' '}
      <GridLoader
        fill="rgb(15, 191, 228)"
        className="inline-block w-4 h-4 my-auto ml-4"
      />
    </P>
  </Splash>
);

const Guard = ({ children: renderFunction, ...otherProps }) => {
  const {
    loginState: { status, userProfile },
    init,
  } = useAuth();

  useEffect(() => {
    switch (status) {
      case 'init':
        init();
        break;
      case 'loggedOut':
        navigate('/login', { replace: true });
        break;
    }
  });

  switch (status) {
    case 'init':
    case 'loading':
      return <Checking />;
    case 'loggedOut':
      return (
        <Splash>
          <P className="m-auto text-2xl">
            Vous n'êtes plus connecté, retour à la page de connexion.
          </P>
        </Splash>
      );
    case 'loggedIn':
      return renderFunction({ userProfile, ...otherProps });
    default:
      return <div>État de la connexion inconnu</div>;
  }
};

export const withGuard = (Component) => (props) => (
  <Guard>
    {({ userProfile }) => <Component {...props} userProfile={userProfile} />}
  </Guard>
);

export default Guard;
