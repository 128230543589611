import React from 'react';

import routes from 'src/config/routes';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import Loading from 'src/components/design-system/loading';
import Header from 'src/components/design-system/header';

import EditForm from './editForm';

const EditUser = ({ id }) => {
  const { requestState } = useAxiosNow({
    url: routes.user(id),
  });

  return (
    <>
      <Header title="Edition d'un administrateur"></Header>
      <Loading requestState={requestState}>
        {(rs) => {
          const userData = rs.data.data;
          return (
            <EditForm
              userData={userData}
              axiosConfig={{
                url: routes.user(userData.id),
                method: 'put',
              }}
            />
          );
        }}
      </Loading>
    </>
  );
};

export default EditUser;
