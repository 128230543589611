import React from 'react';

import Header from 'src/components/design-system/header';
import SecondaryHeader from 'src/components/design-system/header/secondary';
import Loading from 'src/components/design-system/loading';

import routes from 'src/config/routes';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import { formatFamilyName } from 'src/utils/famille';

import Contact from './contact';
import Enfants from './enfants';
import Paiements from './paiements';

const ViewFamille = ({ id }) => {
  const { requestState: userRequestState } = useAxiosNow({
    url: routes.famille(id),
  });

  return (
    <Loading requestState={userRequestState}>
      {(rs) => {
        const {
          data: { data: famille },
        } = rs;
        return (
          <>
            <Header
              title={`Famille ${formatFamilyName(famille)}`}
              back={{
                to: '/admin/familles',
                label: 'Retour à la liste des familles',
              }}
            />
            <SecondaryHeader
              title="Informations générales"
              className="mt-4"
              action={{
                to: `/admin/familles/${famille.id}/edit`,
                label: 'Mettre à jour',
              }}
            />
            <Contact famille={famille} />
            <SecondaryHeader
              title="Enfants et inscriptions"
              className="mt-4"
              action={{
                to: `admin/familles/${id}/inscription/new`,
                label: 'Nouvelle inscription',
              }}
            />
            <Enfants famille={famille} />
            <SecondaryHeader title="Paiements" className="mt-4" />
            <Paiements famille={famille} />
          </>
        );
      }}
    </Loading>
  );
};

export default ViewFamille;
