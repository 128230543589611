import React, { useState } from 'react';
import FormLine from 'src/components/design-system/form/line';
import FormSection from 'src/components/design-system/form/section';
import Select from 'src/components/design-system/form/select';
import P from 'src/components/design-system/p';
import Strong from 'src/components/design-system/strong';
import ErrorAlert from 'src/components/design-system/alerts/error';

import ActionButon from 'src/components/design-system/buttons/action';

import { convertDateISOToFrFull, convertDateISOToFr } from 'src/utils/date';

const EnfantFormFragment = ({
  enfantPosition,
  creneaux = [],
  sessions = [],
  formData,
  setFormData,
  errors,
}) => {
  const previousEnfantData = formData.enfants_sauvegarde[enfantPosition];
  const previouslyFilled =
    previousEnfantData.creneaux_id && previousEnfantData.sessions_id;
  const [displayChild, setDisplayChild] = useState(previouslyFilled);

  const isAlreadyInscrit = Boolean(previousEnfantData.nextYearInscription);

  const updateTextField = (fieldName) => (e) => {
    const value = e.target.value;
    const updatedEnfant = {
      ...previousEnfantData,
      [fieldName]: e.target.value,
    };

    if (!value) delete updatedEnfant[fieldName];

    const enfants = [...formData.enfants_sauvegarde];
    enfants[enfantPosition] = updatedEnfant;

    setFormData({
      ...formData,
      enfants_sauvegarde: enfants,
    });
  };

  const toggleDisplayChild = () => {
    const nextState = !displayChild;
    if (!nextState) {
      const updatedEnfant = {
        ...previousEnfantData,
      };
      delete updatedEnfant['creneaux_id'];
      delete updatedEnfant['sessions_id'];
      const enfants = [...formData.enfants_sauvegarde];
      enfants[enfantPosition] = updatedEnfant;

      setFormData({
        ...formData,
        enfants_sauvegarde: enfants,
      });
    }
    setDisplayChild(nextState);
  };

  return (
    <div className="mt-6 sm:mt-5">
      <FormSection>
        <div className="flex flex-row items-baseline">
          <div className="flex-grow">
            Enfant {enfantPosition + 1} : {previousEnfantData.prenom}
          </div>
          <ActionButon onClick={toggleDisplayChild}>
            {displayChild ? 'Ne pas inscrire' : 'Inscrire'}
          </ActionButon>
        </div>
      </FormSection>
      {displayChild && (
        <>
          {errors && errors._global && (
            <ErrorAlert className="mt-4" message={errors._global} />
          )}
          {isAlreadyInscrit ? (
            <P>
              <Strong>{previousEnfantData.prenom}</Strong> est déjà inscrit·e
            </P>
          ) : (
            <>
              <FormLine label="Prénom">
                <P>{previousEnfantData.prenom}</P>
              </FormLine>

              <FormLine label="Date de naissance">
                <P>{convertDateISOToFr(previousEnfantData.date_naissance)}</P>
              </FormLine>

              <FormLine label="Horaire" htmlFor="creneaux_id">
                {creneaux && creneaux.length > 0 ? (
                  <Select
                    id="creneaux_id"
                    value={previousEnfantData.creneaux_id}
                    onChange={updateTextField('creneaux_id')}
                    errors={errors}
                  >
                    <option value="">Pas de sélection</option>
                    {creneaux.map((c) => (
                      <option value={c.id}>
                        {c.jour_seul} {c.heure_debut} – {c.intitule} –
                        {c.age_mois_min}-{c.age_mois_max} mois
                      </option>
                    ))}
                  </Select>
                ) : (
                  <P className="text-red-600">Plus de créneau disponibles.</P>
                )}
              </FormLine>

              <FormLine label="Date de début" htmlFor="sessions_id">
                {sessions && sessions.length !== 0 ? (
                  <Select
                    id="sessions_id"
                    value={previousEnfantData.sessions_id}
                    onChange={updateTextField('sessions_id')}
                    errors={errors}
                  >
                    <option value="">Pas de sélection</option>
                    {sessions.map((s) => (
                      <option value={s.id}>
                        {convertDateISOToFrFull(s.date_debut_activite)}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <P className="text-red-600">
                    Pas de sessions disponibles pour le moment.
                  </P>
                )}
              </FormLine>
            </>
          )}{' '}
        </>
      )}
    </div>
  );
};

export default EnfantFormFragment;
