import React from 'react';
import * as Form from 'src/components/design-system/form';

const CreneauFilterLine = ({ creneaux, setFormData, formData }) => {
  const toggleCreneau = (id) => () => {
    const creneauxPos = formData.creneaux
      ? formData.creneaux.indexOf(id)
      : null;
    let newCreneaux;

    if (creneauxPos === null) newCreneaux = [id];
    else {
      newCreneaux = [...formData.creneaux];
      if (creneauxPos === -1) newCreneaux.push(id);
      else {
        newCreneaux.splice(creneauxPos);
      }
    }

    setFormData({
      ...formData,
      creneaux: newCreneaux,
    });
  };

  const isChecked = (id) =>
    formData.creneaux ? formData.creneaux.indexOf(id) !== -1 : false;

  return (
    <Form.Line label="Choix des créneaux">
      {creneaux.map((c) => (
        <Form.Checkbox
          label={`${c.ref} - ${c.intitule} à ${c.heure_debut}`}
          key={c.ref}
          checked={isChecked(c.id)}
          id={c.id}
          name="creneaux"
          onChange={toggleCreneau(c.id)}
        />
      ))}
    </Form.Line>
  );
};

export default CreneauFilterLine;
