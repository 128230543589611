import React from 'react';

const MainStats = ({ data }) => (
  <>
    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Informations générales
      </h3>
      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
        Les principales informations de la saison.
      </p>
    </div>
    <div className="px-4 py-5 sm:p-0">
      <dl>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Nombre de familles inscrites (adhérents)
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {data.totalFamilles}
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Nombre d'enfants inscrits
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {data.totalEnfants}
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Inscriptions payées
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {data.totalPaye} euros
          </dd>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <dt className="text-sm leading-5 font-medium text-gray-500">
            Inscriptions impayées
          </dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {data.totalImpaye} euros
          </dd>
        </div>
      </dl>
    </div>
  </>
);

export default MainStats;
