import React from 'react';

import ErrorAlert from 'src/components/design-system/alerts/error';
import ActionButton from 'src/components/design-system/buttons/action';
import P from 'src/components/design-system/p';

import EnfantFormFragment from './enfant';

const regex = /enfants\.(\d+)(?:\.(\w+))?/;
const getEnfantsErrors = (errors) => {
  if (!errors || errors.length === 0) return [];
  const errorsByEnfants = [];
  Object.keys(errors).forEach((k) => {
    const match = regex.exec(k);
    if (match !== null) {
      const pos = parseFloat(match[1]);
      const key = match[2] || '_global';
      if (errorsByEnfants[pos]) errorsByEnfants[pos][key] = errors[k];
      else errorsByEnfants[pos] = { [key]: errors[k] };
    }
  });
  return errorsByEnfants;
};

const EnfantsForm = ({
  requestState,
  formData,
  setFormData,
  sessions,
  creneaux,
}) => {
  const isReinscription =
    formData.enfants_sauvegarde && formData.enfants_sauvegarde.length > 0;
  const addEnfant = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      enfants: [
        ...formData.enfants,
        {
          nom: '',
          prenom: '',
          sexe: '',
          creneaux_id: undefined,
          sessions_id: undefined,
        },
      ],
    });
  };

  const errorsByEnfants = getEnfantsErrors(
    requestState.error && requestState.error.errors
  );

  return (
    <>
      {requestState.status === 'error' &&
        requestState.error.errors &&
        requestState.error.errors.enfants && (
          <ErrorAlert
            className="mt-4"
            message="Vous devez inscrire au moins un enfant"
          />
        )}

      {(!formData.enfants || formData.enfants.length === 0) &&
      !isReinscription ? (
        <div className="flex flex-col content-center justify-center h-32">
          <P className="m-auto text-center">
            <div className="mb-4 text-xl tracking-tight">
              Vous n'avez pas encore ajouté vos enfants.
            </div>
            <ActionButton onClick={addEnfant} className="m-auto">
              Ajouter le premier enfant
            </ActionButton>
          </P>
        </div>
      ) : (
        <>
          {formData.enfants.map((_, i) => {
            const errEnfant = errorsByEnfants[i];
            return (
              <EnfantFormFragment
                key={i}
                enfantPosition={i}
                formData={formData}
                setFormData={setFormData}
                creneaux={creneaux}
                sessions={sessions}
                requestState={requestState}
                errors={errEnfant}
              />
            );
          })}

          <div className="flex content-center pt-8 mt-4 ">
            <ActionButton onClick={addEnfant} className="m-auto">
              Ajouter un enfant
            </ActionButton>
          </div>
        </>
      )}
    </>
  );
};

export default EnfantsForm;
