import React from 'react';
import classnames from 'classnames';

import ActionButton from 'src/components/design-system/buttons/action';

const SecondaryHeader = ({
  className,
  children,
  title,
  action = {},
  ...otherProps
}) => (
  <div
    className={classnames('flex md:flex-row flex-wrap px-2 md:px-0', className)}
    {...otherProps}
  >
    <h2 className="text-2xl font-semibold leading-tight text-gray-900 flex-grow">
      {title}
    </h2>
    {action.label && <ActionButton to={action.to}>{action.label}</ActionButton>}
    {children}
  </div>
);

export default SecondaryHeader;
