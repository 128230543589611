import React from 'react';

import routes from 'src/config/routes';
import Header from 'src/components/design-system/header';

import EditForm from './editForm';

const AddUser = () => {
  return (
    <>
      <Header title="Ajout d'un administrateur"></Header>
      <EditForm
        axiosConfig={{
          url: routes.user(),
          method: 'POST',
        }}
      />
    </>
  );
};

export default AddUser;
