import React, { useState, useEffect } from 'react';
import SimpleInput from '../../../design-system/form/input';
import classnames from 'classnames';

const SearchForm = ({
  onSubmit,
  className,
  defaultValue = '',
  ...otherProps
}) => {
  const [query, setQuery] = useState(defaultValue);
  useEffect(() => {
    setQuery(defaultValue);
  }, [defaultValue]);
  return (
    <form
      onSubmit={(e) => {
        onSubmit(e, query);
      }}
      className={classnames('mt-1 flex rounded-md shadow-sm', className)}
      {...otherProps}
    >
      <div className="relative flex-grow focus-within:z-10">
        <input
          className="block w-full pl-5 rounded-none form-input rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          placeholder="Rechercher par nom de famille"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </div>
      <button
        className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 border border-gray-300 leading-5 rounded-r-md bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
        type="submit"
      >
        <svg
          className="w-5 h-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
            fill-rule="evenodd"
          ></path>
        </svg>
        <span className="hidden ml-2 md:inline">Chercher</span>
      </button>
    </form>
  );
};

export default SearchForm;
