import React from 'react';

import { useAuth } from 'src/components/authentication';
import Header from 'src/components/design-system/header';

import ConfigDashView from './config';
import AvailableCreneaux from './availability';
import Inscription from './inscriptions';

const Dashboard = () => {
  const {
    loginState: { userProfile },
  } = useAuth();

  return (
    <>
      <Header title="Tableau de bord">
        <div className="w-full">Bienvenue {userProfile.name} 👋.</div>
        <ConfigDashView />
        <AvailableCreneaux />
        <Inscription />
      </Header>
    </>
  );
};
export default Dashboard;
