import qs from 'qs';

export const get = (location = {}, initialValues = {}) => {
  if (location.search)
    return {
      ...initialValues,
      ...qs.parse(location.search.slice(1)),
    };

  return initialValues;
};
