import React from 'react';

import * as DesignSystem from 'src/components/design-system';
import classnames from 'classnames';

const Card = ({ className, children, onSubmit, ...otherProps }) => (
  <DesignSystem.Card className={classnames('', className)} {...otherProps}>
    <form onSubmit={onSubmit}>{children}</form>
  </DesignSystem.Card>
);

export default Card;
