import React from 'react';
import { Link } from 'gatsby';

export const MobileMenuItem = ({ label, to, icon: Icon, onClick }) => (
  <Link
    to={to}
    getProps={({ isCurrent }) => ({
      className: isCurrent
        ? 'flex items-center px-2 py-2 text-base font-medium text-gray-900 bg-gray-100 group leading-6 rounded-md focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150'
        : 'flex items-center px-2 py-2 mt-1 text-base font-medium text-gray-600 group leading-6 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150',
    })}
    onClick={onClick}
  >
    <Icon className="w-6 h-6 mr-4 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" />
    {label}
  </Link>
);

export const DesktopMenuItem = ({ label, to, icon: Icon }) => (
  <Link
    to={to}
    getProps={({ isCurrent }) => ({
      className: isCurrent
        ? 'flex items-center px-2 py-2 text-sm font-medium text-gray-900 bg-gray-100 group leading-5 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150'
        : 'flex items-center px-2 py-2 mt-1 text-sm font-medium text-gray-600 group leading-5 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150',
    })}
  >
    <Icon className="w-6 h-6 mr-3 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" />
    {label}
  </Link>
);
