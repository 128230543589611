import React from 'react';

import routes from 'src/config/routes';
import Header from 'src/components/design-system/header';

import CreneauForm from './form';

const AddCreneau = ({ saisonId }) => {
  return (
    <>
      <Header title="Ajouter un nouveau créneau" />
      <CreneauForm
        initialValue={{
          saison_id: saisonId,
          position: 0,
          ref: '',
          affichage: false,
          jour: 'samedi',
          heure_debut: '',
          heure_fin: '',
          intitule: '',
          age_mois_min: 0,
          age_mois_max: 0,
          nbplace_total: 0,
        }}
        axiosConfig={{
          method: 'POST',
          url: routes.creneaux(),
        }}
        saisonId={saisonId}
      />
    </>
  );
};

export default AddCreneau;
