import React from 'react';
import ErrorAlert from 'src/components/design-system/alerts/error';
import Submit from 'src/components/design-system/buttons/submit';
import * as Form from 'src/components/design-system/form';
import routes from 'src/config/routes';
import useForm, { updateTextField } from 'src/hooks/useForm';
import { convertDateFrToISO, convertDateISOToFr } from 'src/utils/date';
import { formatSaison } from 'src/utils/saison';

const InscriptionForm = ({ enfant, creneaux, familleId, inscription }) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
    errors,
    isLoading,
  } = useForm({
    initialValue: inscription,
    axiosConfig: {
      method: 'PUT',
      url: routes.inscription(inscription.id),
    },
    redirectUrl: `/admin/familles/${familleId}`,
    formToPayload: (form) => ({
      ...form,
      date_certificat_medical: convertDateFrToISO(form.date_certificat_medical),
    }),
  });

  const updateField = updateTextField(setFormData, formData);

  return (
    <Form.Card
      className="mt-4"
      title={`Inscription ${formatSaison(inscription.saison)}`}
      onSubmit={submitForm}
    >
      {requestState.status === 'error' && (
        <ErrorAlert className="mt-4" message={requestState.error.message} />
      )}
      <>
        <Form.Line label="Créneau pour l'année en cours" htmlFor="creneaux_id">
          <Form.Select
            id="creneaux_id"
            onChange={updateField('creneaux_id')}
            value={formData.creneaux_id}
            errors={errors}
          >
            {creneaux.map((c) => (
              <option value={c.id}>
                {c.ref}-{c.intitule}
              </option>
            ))}
          </Form.Select>
        </Form.Line>

        <Form.Line
          label="Certificat médical"
          help="Certificat d'aptitude sportive pour les enfants. Doit être renouvelé chaque année, et dois dater de moins de trois mois lors de la vérification."
          htmlFor="certificat_medical_fourni"
        >
          <Form.Select
            id="certificat_medical_fourni"
            value={formData.certificat_medical_fourni}
            onChange={updateField('certificat_medical_fourni')}
            errors={errors}
          >
            <option value="1">Oui</option>
            <option value="0">Non</option>
          </Form.Select>
        </Form.Line>
        <Form.Line label="Date du certificat">
          <Form.Date
            id="date_certificat_medical"
            value={convertDateISOToFr(formData.date_certificat_medical)}
            onChange={updateField('date_certificat_medical')}
          />
        </Form.Line>
        <Form.Footer>
          <Submit type="submit" disabled={isFormPristine} isLoading={isLoading}>
            Mettre à jour l'inscription
          </Submit>
        </Form.Footer>
      </>
    </Form.Card>
  );
};

export default InscriptionForm;
