import React from 'react';
import { Router } from '@reach/router';

import Log from './log';
import Send from './send';
import View from './view';

const Mailing = () => (
  <Router>
    <Log path="/" />
    <Send path="/send/*" />
    <View path="/view/:id" />
  </Router>
);

export default Mailing;
