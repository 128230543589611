import React from 'react';

import Header from 'src/components/design-system/header';
import Loading from 'src/components/design-system/loading';
import ErrorAlert from 'src/components/design-system/alerts/error';
import routes from 'src/config/routes';
import useAxios, { useAxiosNow, isLoading } from 'src/hooks/axios/useAxios';
import FormSaison from './form';
import { navigate } from 'gatsby';

const EditSaison = ({ id }) => {
  const { requestState: userRequestState } = useAxiosNow({
    url: routes.saison(id),
  });

  const { requestState: rsDelete, load: deleteSaison } = useAxios({
    method: 'delete',
    url: routes.saison(id),
  });

  return (
    <div>
      <Header
        title="Mettre à jour la saison"
        action={{
          label: 'Supprimer',
          onClick: (e) => {
            e.preventDefault();
            deleteSaison().then(({ error }) => {
              if (!error) navigate('/admin/saisons');
            });
          },
          color: 'red',
          mustConfirm: true,
          isLoading: isLoading(rsDelete),
          confirmationMessage:
            'Êtes vous sur de vouloir supprimer cette saison?',
        }}
      >
        {rsDelete.status === 'error' && (
          <ErrorAlert
            className="mt-4 w-full"
            message={rsDelete.error.message}
          />
        )}
      </Header>
      <Loading requestState={userRequestState}>
        {(rs) => (
          <FormSaison
            axiosConfig={{
              url: routes.saison(id),
              method: 'put',
            }}
            initialValue={rs.data.data}
          />
        )}
      </Loading>
    </div>
  );
};

export default EditSaison;
