import React from 'react';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import Loading from 'src/components/design-system/loading';
import * as DesignSystem from 'src/components/design-system';

import Table from 'src/components/design-system/table';
import H2 from 'src/components/design-system/h2';
import classnames from 'classnames';

const formatSaison = (saison) => (
  <div className="w-full text-right">
    <DesignSystem.Link to={`/admin/saisons/${saison.id}`}>
      {saison.annee_debut}-{saison.annee_fin}
    </DesignSystem.Link>
  </div>
);

const formatStatutSaison = (statut) => (
  <div
    className={classnames('w-full text-right', {
      'text-green-900': statut === 1,
      'text-red-900': statut !== 1,
    })}
  >
    {statut === 1 ? 'ouvertes' : 'fermées'}
  </div>
);

const ConfigDashView = ({ config }) => {
  return (
    <>
      <H2 className="mt-8">Saisons en cours</H2>
      <Table
        className="mt-2"
        lines={[
          ['Saison courante', formatSaison(config.saisonCourante)],
          ['Saison prochaine', formatSaison(config.saisonProchaine)],
        ]}
      />

      <H2 className="mt-4">Statut des inscriptions</H2>
      <Table
        className="mt-2"
        lines={[
          [
            <span>
              Inscriptions des <strong>membres du CA</strong>
            </span>,
            formatStatutSaison(config.reinscription_ca_ouverte),
          ],
          [
            <span>
              Inscriptions des <strong>anciens adhérents</strong>
            </span>,
            formatStatutSaison(config.reinscription_ouverte),
          ],
          [
            <span>
              Inscriptions des <strong>nouveaux adhérents</strong>
            </span>,
            formatStatutSaison(config.inscription_ouverte),
          ],
        ]}
      />
    </>
  );
};

const ConfigDashViewWithData = () => {
  const { requestState } = useAxiosNow({
    url: routes.configuration(),
    retry: 3,
  });

  return (
    <div className="w-full">
      <Loading requestState={requestState}>
        {(rs) => <ConfigDashView config={rs.data.data} />}
      </Loading>
    </div>
  );
};

export default ConfigDashViewWithData;
