import React from 'react';

import ErrorAlert from 'src/components/design-system/alerts/error';
import Submit from 'src/components/design-system/buttons/submit';
import FormFooter from 'src/components/design-system/form/footer';
import * as DesignSystem from 'src/components/design-system';

import { LoadingMany } from 'src/components/design-system/loading';
import useForm from 'src/hooks/useForm';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';

import EnfantsForm from 'src/components/inscription/formFragments/enfants';
import EnfantsInscritsForm from 'src/components/inscription/formFragments/enfantsInscrits';

import { formatFamilyName } from 'src/utils/famille';
import { useConfig } from '../../../configuration';

const defaultFormValue = (famille) => ({
  reduc: false,

  famille_id: famille.id,

  enfants_sauvegarde: famille.enfants || [],
  enfants: [
    /* 
          nom: '',
          prenom: '',
          sexe: '',
          creneaux_id: undefined
          sessions_id: undefined,
         */
  ],
});

const InformationsReinscription = ({ creneaux, sessions, famille }) => {
  const { formData, setFormData, requestState, submitForm } = useForm({
    initialValue: defaultFormValue(famille),
    axiosConfig: {
      method: 'POST',
      url: routes.inscriptionSave(),
    },
    redirectUrl: `/admin/familles/${famille.id}`,
  });

  const errors =
    requestState.status === 'error' ? requestState.error.errors : {};

  return (
    <form onSubmit={submitForm}>
      {requestState.status === 'error' && (
        <ErrorAlert
          className="mt-4"
          message={
            (errors && errors._global) ||
            'Certaines informations sont manquantes ou invalides.'
          }
        />
      )}

      <EnfantsInscritsForm
        formData={formData}
        setFormData={setFormData}
        requestState={requestState}
        creneaux={creneaux}
        sessions={sessions}
      />

      <EnfantsForm
        formData={formData}
        setFormData={setFormData}
        requestState={requestState}
        creneaux={creneaux}
        sessions={sessions}
      />

      <FormFooter>
        <Submit type="submit" isLoading={requestState.status === 'loading'}>
          Valider
        </Submit>
      </FormFooter>
    </form>
  );
};

const InformationReinscriptionWithData = ({ familleId }) => {
  const rsConfig = useConfig();
  const { requestState: requestStateAvailableCreneaux } = useAxiosNow({
    url: routes.getAvailableCreneaux(),
  });

  const { requestState: requestStateFamille } = useAxiosNow({
    url: routes.famille(familleId),
  });

  const saisonProchaine =
    rsConfig.status === 'loaded' ? rsConfig.data.data.saisonProchaine : {};
  const saisonLabel = `${saisonProchaine.annee_debut}-${saisonProchaine.annee_fin}`;

  return (
    <>
      <DesignSystem.Header
        title={`Nouvelle inscription ${saisonLabel}`}
        back={{
          to: `/admin/familles/${familleId}`,
          label: 'Retour à la famille',
        }}
      />
      <LoadingMany
        requestStates={[requestStateFamille, requestStateAvailableCreneaux]}
        errorComponent={() => (
          <DesignSystem.P>Une erreur s'est produite.</DesignSystem.P>
        )}
      >
        {([rsFamille, rsCreneaux]) => {
          const { data: famille } = rsFamille.data;
          const {
            data: {
              data: { creneaux, sessions, prebook },
            },
          } = rsCreneaux;

          return (
            <DesignSystem.Card
              className="mt-4"
              title={`Famille ${formatFamilyName(famille)}`}
            >
              <InformationsReinscription
                famille={famille}
                creneaux={creneaux}
                sessions={sessions}
                prebook={prebook}
              />
            </DesignSystem.Card>
          );
        }}
      </LoadingMany>
    </>
  );
};

export default InformationReinscriptionWithData;
