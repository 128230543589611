import React from 'react';

import * as Form from 'src/components/design-system/form';

import useForm from 'src/hooks/useForm';
import { isLoading } from 'src/hooks/axios/useAxios';

import ErrorAlert from 'src/components/design-system/alerts/error';
import CancelButton from 'src/components/design-system/buttons/cancel';
import Submit from 'src/components/design-system/buttons/submit';
import FormFooter from 'src/components/design-system/form/footer';
import SimpleInput from 'src/components/design-system/form/input';
import FormLine from 'src/components/design-system/form/line';
import FormSection from 'src/components/design-system/form/section';
import TextArea from 'src/components/design-system/form/text';
import Toggle from 'src/components/design-system/form/toggle';

const FamilleForm = ({
  initialValue,
  axiosConfig,
  labelSubmit = 'Créer',
  cancelUrl = '/admin/familles',
}) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue,
    axiosConfig,
    redirectUrl: cancelUrl,
  });

  const updateTextField = (fieldName) => (e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  return (
    <Form.Card onSubmit={submitForm} className="mt-4">
      {requestState.status === 'error' && (
        <ErrorAlert className="mt-4" message={requestState.error.message} />
      )}
      <FormSection className="-mt-6">Parent 1</FormSection>
      <FormLine label="Prénom" htmlFor="parent1_prenom">
        <SimpleInput
          id="parent1_prenom"
          value={formData.parent1_prenom}
          onChange={updateTextField('parent1_prenom')}
          requestState={requestState}
        />
      </FormLine>
      <FormLine label="Nom" htmlFor="parent1_nom">
        <SimpleInput
          id="parent1_nom"
          value={formData.parent1_nom}
          onChange={updateTextField('parent1_nom')}
          requestState={requestState}
        />
      </FormLine>
      <FormSection>Parent 2</FormSection>
      <FormLine label="Prénom" htmlFor="parent2_prenom">
        <SimpleInput
          id="parent2_prenom"
          value={formData.parent2_prenom}
          onChange={updateTextField('parent2_prenom')}
          requestState={requestState}
        />
      </FormLine>
      <FormLine label="Nom" htmlFor="parent2_nom">
        <SimpleInput
          id="parent2_nom"
          value={formData.parent2_nom}
          onChange={updateTextField('parent2_nom')}
          requestState={requestState}
        />
      </FormLine>
      <FormSection>Contact</FormSection>
      <FormLine
        label="E-mail principal"
        help="Cet e-mail sert aussi à l'identification des adhérents sur le site web"
        htmlFor="mail_principal"
      >
        <SimpleInput
          id="mail_principal"
          value={formData.mail_principal}
          onChange={updateTextField('mail_principal')}
          requestState={requestState}
        />
      </FormLine>
      <FormLine label="E-mail secondaire" htmlFor="mail_secondaire">
        <SimpleInput
          id="mail_secondaire"
          value={formData.mail_secondaire}
          onChange={updateTextField('mail_secondaire')}
          requestState={requestState}
        />
      </FormLine>
      <FormLine label="Téléphone principal" htmlFor="tel_principal">
        <SimpleInput
          id="tel_principal"
          value={formData.tel_principal}
          onChange={updateTextField('tel_principal')}
          requestState={requestState}
        />
      </FormLine>
      <FormLine label="Téléphone secondaire" htmlFor="tel_secondaire">
        <SimpleInput
          id="tel_secondaire"
          value={formData.tel_secondaire}
          onChange={updateTextField('tel_secondaire')}
          requestState={requestState}
        />
      </FormLine>
      <FormSection>Adresse</FormSection>
      <FormLine label="Adresse" help="" htmlFor="adresse">
        <SimpleInput
          id="adresse"
          value={formData.adresse}
          onChange={updateTextField('adresse')}
          requestState={requestState}
        />
      </FormLine>
      <FormLine label="Code postal" help="" htmlFor="code_postal">
        <SimpleInput
          id="code_postal"
          value={formData.code_postal}
          onChange={updateTextField('code_postal')}
          requestState={requestState}
        />
      </FormLine>
      <FormLine label="Ville" help="" htmlFor="ville">
        <SimpleInput
          id="ville"
          value={formData.ville}
          onChange={updateTextField('ville')}
          requestState={requestState}
        />
      </FormLine>
      <FormSection>Vie associative</FormSection>
      <FormLine
        label="Participation au CA"
        help="Les membres du CA sont parmis les membres les plus actifs de l'association"
      >
        <Toggle
          id="formData.is_membreCA"
          checked={formData.is_membreCA}
          onChange={(is_membreCA) => {
            setFormData({ ...formData, is_membreCA });
          }}
        />
      </FormLine>
      <FormSection>Commentaires</FormSection>
      <FormLine
        label="Commentaires"
        help="Champ de texte libre pour ajouter des informations complémentaires sur le compte de ces adhérents"
      >
        <TextArea
          id="formData.commentaire"
          value={formData.commentaire}
          onChange={updateTextField('commentaire')}
        />
      </FormLine>
      <FormFooter>
        <CancelButton to={cancelUrl}>Retour</CancelButton>
        <Submit
          type="submit"
          disabled={isFormPristine}
          isLoading={isLoading(requestState)}
        >
          {labelSubmit}
        </Submit>
      </FormFooter>
    </Form.Card>
  );
};

export default FamilleForm;
