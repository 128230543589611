import React from 'react';
import { Router } from '@reach/router';

import ListFamille from 'src/components/admin/famille/list';
import AddFamille from 'src/components/admin/famille/add';
import EditFamille from 'src/components/admin/famille/edit';
import ViewFamille from 'src/components/admin/famille/view';

import AddEnfant from 'src/components/admin/famille/enfant/add';
import EditEnfant from 'src/components/admin/famille/enfant/edit';

import AddInscription from 'src/components/admin/famille/inscription/new';

import EditPaiement from 'src/components/admin/famille/paiement/edit';

const FamillesRoutes = () => (
  <Router>
    <ListFamille path="/" />
    <AddFamille path="/new" />
    <ViewFamille path="/:id" />
    <EditFamille path="/:id/edit" />

    <AddEnfant path="/:familleId/enfant/new" />
    <EditEnfant path="/:familleId/enfant/:id" />

    <AddInscription path="/:familleId/inscription/new" />

    <EditPaiement path="/:familleId/paiement/:paiementId" />
  </Router>
);

export default FamillesRoutes;
