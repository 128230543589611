import React from 'react';
import classnames from 'classnames';

const FormFooter = ({ className, children, ...otherProps }) => (
  <div
    className={classnames(
      'pt-5 mt-8 border-t border-gray-200 px-2 md:px-0',
      className
    )}
    {...otherProps}
  >
    <div className="flex justify-end">{children}</div>
  </div>
);

export default FormFooter;
