import React from 'react';
import classnames from 'classnames';
import { TailSpin as Loader } from 'svg-loaders-react';

const Submit = ({
  className,
  disabled,
  isLoading = undefined,
  children = 'Enregistrer',
  value,
  ...otherProps
}) => {
  return (
    <span className="inline-flex ml-3 rounded-md shadow-sm">
      <button
        type="submit"
        disabled={disabled || isLoading === true}
        value={value}
        className={classnames(
          className,
          'inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent leading-5 rounded-md focus:outline-none transition duration-150 ease-in-out',
          {
            'bg-blue-600 hover:bg-blue-500 active:bg-blue-700 text-white focus:border-blue-700 focus:shadow-outline-blue ': !disabled,
            'bg-gray-200 text-gray-500': disabled,
          }
        )}
        {...otherProps}
      >
        {children}

        {isLoading && <Loader className="h-3" />}
      </button>
    </span>
  );
};

export default Submit;
