import React from 'react';
import routes from 'src/config/routes';
import H1 from 'src/components/design-system/h1';
import EnfantForm from './form';

const AddEnfant = ({ familleId }) => {
  return (
    <>
      <div>
        <H1 className="text-lg font-medium text-gray-900 leading-6">
          Ajouter un nouvel enfant
        </H1>
      </div>
      <EnfantForm
        initialValue={{
          famille_id: familleId,
          nom: '',
          prenom: '',
          vaccination: 'non',
          sexe: '',
        }}
        axiosConfig={{
          method: 'POST',
          url: routes.enfants(),
        }}
        familleId={familleId}
      />
    </>
  );
};

export default AddEnfant;
