import React from 'react';

import * as DesignSytem from 'src/components/design-system';

import Button from 'src/components/design-system/buttons/action';
import useAxios from 'src/hooks/axios/useAxios';

import routes from 'src/config/routes';
import { formatFamilyName } from '../../../utils/famille';

const dataToLines = (data, onClick) =>
  data.map((p) => {
    const name = formatFamilyName(p);
    return [
      name,
      <div className="text-right">
        <DesignSytem.Link to={`/admin/familles/${p.famille_id}`}>
          Voir la famille
        </DesignSytem.Link>
        <Button
          mustConfirm
          confirmationMessage={`Êtes vous sur de vouloir valider la réduction pour la famille ${name}?`}
          onClick={onClick(p.paiement_id)}
          className="ml-6"
        >
          Valider la réduction
        </Button>
      </div>,
    ];
  });

const Reduction = ({ data, reload }) => {
  const { load: updateReduc } = useAxios({
    url: routes.fixReduction(),
    method: 'POST',
  });

  const onClick = (id) => (e) => {
    e.preventDefault();
    updateReduc({
      payload: { id },
    }).then(reload);
  };

  return data && data.length > 0 ? (
    <DesignSytem.Table lines={dataToLines(data, onClick)} className="mt-4" />
  ) : (
    <DesignSytem.Card className="mt-4">
      <DesignSytem.P>Aucun justificatif de réduction en attente</DesignSytem.P>
    </DesignSytem.Card>
  );
};

export default Reduction;
