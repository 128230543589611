import React, { useEffect } from 'react';

import * as DesignSystem from 'src/components/design-system';

import routes from 'src/config/routes';
import { useAxiosNow } from 'src/hooks/axios/useAxios';

import FamilleForm from './form';

const EditFamille = ({ id }) => {
  const { requestState } = useAxiosNow({
    url: routes.famille(id),
  });

  return (
    <>
      <DesignSystem.Header
        title="Mise à jour d'une famille"
        back={{ to: `/admin/familles/${id}`, label: 'Retour' }}
      />

      <DesignSystem.Loading requestState={requestState}>
        {(rs) => {
          const data = rs.data.data;
          return (
            <FamilleForm
              initialValue={data}
              axiosConfig={{
                method: 'PUT',
                url: routes.famille(id),
              }}
              labelSubmit="Mettre à jour"
              cancelUrl={`/admin/familles/${id}`}
            />
          );
        }}
      </DesignSystem.Loading>
    </>
  );
};

export default EditFamille;
