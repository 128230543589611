import React from 'react';

import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';

import Loading from 'src/components/design-system/loading';
import Table from 'src/components/design-system/table';

import StyledLink from 'src/components/design-system/link';
import Header from 'src/components/design-system/header';
import ActionButton from 'src/components/design-system/buttons/action';

import ResetPasswordButton from './resetLink';

const Team = () => {
  const { requestState } = useAxiosNow({ url: routes.team() });

  return (
    <div>
      <Header title="Administrateurs">
        <ActionButton to="/admin/team/new">Ajouter un utilisateur</ActionButton>
      </Header>
      <Loading requestState={requestState}>
        {(requestState) => {
          const lines = requestState.data.data.map((u) => [
            u.name,
            u.email,
            <div className="flex justify-end items-baseline">
              <StyledLink key="" to={`/admin/team/${u.id}`}>
                Editer
              </StyledLink>
              <ResetPasswordButton className="ml-4" email={u.email} />
            </div>,
          ]);
          return (
            <Table
              headers={['Nom', 'E-mail', '']}
              lines={lines}
              className="mt-4"
            />
          );
        }}
      </Loading>
    </div>
  );
};

export default Team;
