import React from 'react';
import { Router } from '@reach/router';

import AddSaison from 'src/components/admin/saison/add';
import EditSaison from 'src/components/admin/saison/edit';
import ListSaisons from 'src/components/admin/saison/list';
import ViewSaison from 'src/components/admin/saison/view';

import AddSession from 'src/components/admin/saison/session/add';
import EditSession from 'src/components/admin/saison/session/edit';

import AddCreneau from 'src/components/admin/saison/creneau/add';
import EditCreneau from 'src/components/admin/saison/creneau/edit';

const SaisonRoutes = () => {
  return (
    <Router>
      <ListSaisons path="/" />
      <EditSaison path="/:id/edit" />
      <ViewSaison path="/:id" />
      <AddSaison path="/new" />

      <AddSession path="/:saisonId/sessions/new" />
      <EditSession path="/:saisonId/sessions/:sessionId" />

      <AddCreneau path="/:saisonId/creneaux/new" />
      <EditCreneau path="/:saisonId/creneaux/:creneauId" />
    </Router>
  );
};

export default SaisonRoutes;
